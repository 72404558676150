import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "recompose";
import withStyles from "@material-ui/core/styles/withStyles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";

import axios from "axios";
import {  withSnackbar } from "notistack";
import LinearProgress from "@material-ui/core/LinearProgress";
import ChooseModelType from "./ChooseModelType";
import Loading from "../../components/Loading";
import { NavLink } from "react-router-dom";
import config from "../../config";
import Upload from "gcs-browser-upload";
import moment from "moment";


const styles = theme => ({
  appBar: {
    position: "relative"
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(4))]: {
      maxWidth: 1200,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(6))]: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3)
    }
  },
  stepper: {
    padding: `${theme.spacing(3)}px 0 ${theme.spacing(5)}px`
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end"
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1)
  }
});

const steps = ["Choose Model Type", "Choose Dataset", "Set up parameters", "Train"];

class NuevoRegistro extends React.Component {
  state = {
    activeStep: 0,
    modelType:''
  };

  componentWillMount(nextProps, nextState) {

  }

  getStepContent = step => {
    switch (step) {
      case 0:
        return (
          <ChooseModelType
            modelType={this.state.modelType}
            handleSetObject={this.handleSetObject}
          />
        );
      case 1:
        return null;
      case 2:
        return null;
      default:
        throw new Error("Unknown step");
    }
  };

  handleSetObject = (name, field) => event => {
    const newState = { ...this.state };
    newState[name] = event.target.value;

    this.setState(newState);
  };

  handleSetValue = name => event => {
    // // console.log(name,": ", event.target.value)
    // this.setState(
    //   {
    //     [name]: event.target.value
    //   },
    //   () => {
    //     //console.log("state:", this.state )
    //   }
    // );
  };

  allowSiguiente = () => {
    this.setState(
      prevState => ({
        activeStep: prevState.activeStep + 1
      }),
      () => {}
    );
  };

  handleNewPrediction = () => {
    // this.setState(
    //   prevState => ({
    //     activeStep: 1,
    //     files: [],
    //     prediction: [],
    //     numImagesPred: 0,
    //     loadingPostingImage: false
    //   }),
    //   () => {}
    // );
  };

  handleChangeModel = () => {
    // this.setState(
    //   prevState => ({
    //     activeStep: 0,
    //     model: {
    //       modelName: "",
    //       id: "",
    //       description: "",
    //       type: "",
    //       labelMap: {}
    //     },
    //     files: [],
    //     prediction: [],
    //     numImagesPred: 0,
    //     loadingPostingImage: false
    //   }),
    //   () => {}
    // );
  };

  handleSiguiente = () => {
    switch (this.state.activeStep) {
      case 0:
        if (this.state.modelType !== "") {
          this.allowSiguiente();
          break;
        } else {
          this.props.enqueueSnackbar("Please, choose a model type", {
            variant: "error"
          });
          break;
        }
      default:
    }
  };

  handleBack = () => {
    this.setState(prevState => ({
      activeStep: prevState.activeStep - 1
    }));
  };

  finalizarRegistro = () => {
    //AQUÍ TAMBÉ VOLEM FER POST DE TOTA L'ALTRA INFORMACIÓ AL FIRESTORE!!!!
    if (this.state.files.length !== 0) {
      this.setState({
        loadingPostingImage: true
      });
    }
  };

  render() {
    const { classes } = this.props;
    const { activeStep } = this.state;

    //console.log(this.state.activeStep)
    return (
      <React.Fragment>
        <CssBaseline />
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <Stepper
              activeStep={activeStep}
              className={classes.stepper}
              alternativeLabel
            >
              {steps.map(label => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <React.Fragment>
              <React.Fragment>
                {this.getStepContent(activeStep)}
                {false ? (
                  <div style={{ flexGrow: 1 }}>
                  <LinearProgress
                      color="secondary"
                      style={{ marginBottom: "12px" }}
                      variant="determinate"
                      value={this.state.completed}
                    />
                  </div>
                ) : null}
                {false ? <Loading /> : null}
                <div className={classes.buttons}>
                  {activeStep !== 0 && activeStep !== 2 && (
                    <Button
                      disabled={this.state.loadingPostingImage}
                      onClick={this.handleBack}
                      className={classes.button}
                    >
                      BACK
                    </Button>
                  )}
                  {activeStep === 2 && (
                    <Button
                      onClick={this.handleChangeModel}
                      className={classes.button}
                    >
                      CHANGE MODEL
                    </Button>
                  )}
                  {activeStep === 2 && (
                    <Button
                      onClick={this.handleNewPrediction}
                      className={classes.button}
                    >
                      NEW PREDICTION
                    </Button>
                  )}
                  {activeStep !== 2 ? (
                    <Button
                      disabled={false}
                      variant="contained"
                      color="primary"
                      onClick={
                        activeStep < 1
                          ? this.handleSiguiente
                          : activeStep < 2
                          ? this.finalizarRegistro
                          : this.goToGallery
                      }
                      className={classes.button}
                    >
                      {activeStep < 3
                        ? "NEXT"
                        : activeStep < 2
                        ? "TRAIN"
                        : "GO TO GALLERY"}
                    </Button>
                  ) : (
                    <NavLink
                      to="/gallery"
                      style={{ textDecoration: "none", color: "unset" }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                      >
                        GO TO GALLERY
                      </Button>
                    </NavLink>
                  )}
                </div>
              </React.Fragment>
            </React.Fragment>
          </Paper>
        </main>
      </React.Fragment>
    );
  }
}

NuevoRegistro.propTypes = {
  models: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired
};

function modelsState(state) {
  return {
    models: state.models,
    user: state.user
  };
}

export default compose(
  withStyles(styles),
  withSnackbar,
)(NuevoRegistro);
