import React from 'react';
import PropTypes from "prop-types";
import { withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  root: {
    maxWidth: 720,
    justifyContent:'center',
    alignItems:'center'
  },
  media: {
    height: 480,
  },
  rootmax: {
    maxWidth: 1280,
  },
  mediamax: {
    height: 300,
  },
});

class MediaCard extends React.Component {

  render() {
    const { classes } = this.props;
    const { img, step } = this.props;

    if (step===2){
      return (
        <Card className={classes.rootmax}>
            <CardMedia
                className={classes.mediamax}
                image={img}
                title="b&w"
              />
          </Card>
        );
    }
    else{
      return (
        <Card className={classes.root}>
            <CardMedia
                className={classes.media}
                image={img}
                title="b&w"
              />
          </Card>
        );
    }
  }
}

MediaCard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(MediaCard);
