import React from "react";
// import { connect } from 'react-redux';
// import PropTypes from "prop-types";
import Video from "../../components/Video/Video";
// import Loading from "../../components/Loading";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  main:{
    marginBottom: theme.spacing(1),
  },
});

class VideoStream extends React.Component {
  state = {
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.main}>
        <Video/>
      </div>
    );
  }
}

export default withStyles(styles)(VideoStream);
