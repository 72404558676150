import React, { Component } from 'react';
import './App.css';
import Framework from './Views/Framework/Framework'
import { BrowserRouter } from "react-router-dom";
import * as actions from './actions';
import Login from './components/Login'
import { connect } from 'react-redux';
import Starting from './components/Starting'
class App extends Component {

  componentDidMount() {
    setTimeout(() => {this.props.fetchUser()},1000);
  }

  render() {
    switch (this.props.user) {
      case null:
        return(<Starting />)
      case false:
        return(<Login />)
      default:
        if (this.props.user.access===true){
          return (
              <div className="App">
                <BrowserRouter  >
                  <Framework/>
                </BrowserRouter>
              </div>
          );
        }else{
          return(<Login />);
        }
    }
  }
}

function mapStateToProps({ user }) {
  return { user };
}

export default connect(mapStateToProps,actions)(App);
