import { withStyles } from "@material-ui/core/styles";
import React, { Component } from "react";
import CardActionArea from '@material-ui/core/CardActionArea';
import DownIcon from '@material-ui/icons/ArrowDownward';
import PendingIcon from '@material-ui/icons/HourglassEmpty';
import ErrorIcon from '@material-ui/icons/Error';
import CardContent from '@material-ui/core/CardContent';
import { Card, IconButton } from '@material-ui/core/';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import Tooltip from '@material-ui/core/Tooltip';

const styles = theme => ({
  ppending:{
    color: theme.palette.yellow,
  },
  psuccess:{
    color: theme.palette.green,
  },
  pfailure:{
    color: theme.palette.red,
  },
  card: {
    maxWidth: 345,
  },
  fab: {
    margin: '20px',
    padding: '33px',
  },
  media: {
    height: 140,
  },
  cardContent: {
    paddingTop: '5px',
    paddingBottom: '5px',
  },
  pending: {
    paddingTop: '5px',
    paddingBottom: '5px',
    backgroundColor: "grey"
  },
});

class VideoThumbnail extends Component {

  render() {
    const { classes } = this.props;
    // console.log('thumb props',this.props);
    return (
      <Tooltip title={this.props.status==='pending'?'Prediction pending':this.props.status==='failure'?'Error making prediction':this.props.status==='success'?"Download Prediction Json":''}>
      <Card className={classes.card}>
        <CardActionArea
          disabled={this.props.status!=='success'}
          onClick={this.props.onClick}>
          <IconButton style={{background: this.props.status!=='success'? '#FFFFFF': '#BDBDBD'}} aria-label="Add" disabled className={classes.fab}>
              {this.props.status==='failure' ? <ErrorIcon fontSize='large' className={classes.pfailure}/> : this.props.status==='pending' ? <PendingIcon fontSize='large' className={classes.ppending}/> : <DownIcon fontSize='large' className={classes.psuccess}/>}
          </IconButton>
          <CardContent className={this.props.status!=='success'?classes.pending:classes.cardContent}>
            <>
            <Typography variant="overline" style={{marginBottom:'0'}}>
              {
                this.props.video_id
              }
            </Typography>
            <Typography variant="overline" style={{marginBottom:'0'}}>
              {
                this.props.models.find(
                  model => model.id === this.props.model
                ).modelName
              }
            </Typography>
            <Typography variant="overline" style={{marginBottom:'10px'}}>
              {moment(this.props.label).format("DD/MM/YYYY H:mm:ss")}
            </Typography>
            </>
          </CardContent>
        </CardActionArea>
      </Card>
      </Tooltip>
    );
  }
}

export default withStyles(styles)(VideoThumbnail);
