// Imports
import React, { Component } from 'react'
//import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import {  withSnackbar } from "notistack";
// UI Imports
import { Button, Typography } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

const styles = theme => ({
  root: {
    maxWidth: '500px',
    margin: '0 auto',
    width: '60%',
    paddingTop: '5px',
  },
  textField: {
    marginTop: 15,
  },

  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
  },
  formControl: {
    marginTop: theme.spacing(1),
  },
  formControlLabel: {
    marginLeft: 0,
    marginRight: 0,
  },
  formSwitch: {
    marginTop: theme.spacing(1),
  },
});

class DeletePredictionDialog extends Component {
  state = {
    isLoading: false,
    isLoggingIn: false,
    userEdited: false,
    backArrowClicked: false,
    display: 'content',
  }

  onSubmit (event) {
    event.preventDefault();
    this.props.handleDeletePrediction();
  }

  render () {
    
    return (
      <div style={{minWidth:'0px'}}>
        <Dialog
          open={this.props.open}
          onClose={this.props.handleClose}
          aria-labelledby="form-dialog-title"
          style={{paperWidthSm:'0px'}}
          // fullWidth={true}
        >
          <DialogTitle style={{paddingBottom:'0px', minWidth:'0px'}}>{"Delete Prediction"}</DialogTitle>
          <form id="form-tweet" onSubmit={this.onSubmit.bind(this)}>
            <DialogContent style={{paddingTop:'20px', paddingBottom: '5px', minWidth:'0px'}}>
              <Typography>Are you sure you want to delete this prediction</Typography>
            </DialogContent>
            <DialogActions style={{minWidth:'0px'}}>
              <Button onClick={this.props.handleClose}>
                Cancel
              </Button>
              <Button type="submit" color="primary" autoFocus>
                Delete
              </Button>
          </DialogActions>
          </form>
        </Dialog>
      </div>
    )
  }
}

DeletePredictionDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
}

export default compose(
  withStyles(styles),
  withSnackbar
)(DeletePredictionDialog);
