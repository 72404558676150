// Configurations for Frontend
//const NODE_ENV = (process.env.NODE_ENV === 'production') ? 'production' : 'development'

let config = {
  url: {},
  showThreshold: 0.0
}

/*
if (NODE_ENV === 'production') {
  config.url.api = 'https://api.node-express-react-redux-.demo.atulmy.com/' // Change this URL according to your live server
} else {
  config.url.api = '/'
}
*/

config.url.api = '/'

export default config
