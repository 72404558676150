export default [
  {
    value: "Admin",
    label: "Admin",
  },
  {
    value: "User",
    label: "User",
  },
  {
    value: "Stream",
    label: "Stream Viewer",
  },
];
