import { FETCH_USER } from '../actions/types';
import { USER_CURRENT_SET } from '../actions/user';

export default function(state = null, action) {
  //console.log('FETCH USER REDUCER',action.payload );
  switch (action.type) {
    case FETCH_USER:
      return action.payload || false;
    case USER_CURRENT_SET:
      return action.user;
    default:
      return state;
  }
}
