import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
//import Grid from '@material-ui/core/Grid';

const styles = theme => ({
  progress: {
    margin: theme.spacing(2)
  },
  root: {
    width: "100%"
  }
});

function Layout(props) {
  const { classes } = props;
  return (
    <CircularProgress
      className={classes.progress}
      variant={"indeterminate"}
      color={"secondary"}
    />
  );
}

Layout.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Layout);
