import React, {Component} from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { compose } from "recompose";
import {  withSnackbar } from "notistack";
import MUIDataTable from "mui-datatables";
//import EditIcon from "@material-ui/icons/Edit";
import SettingsIcon from "@material-ui/icons/Settings";
import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import CustomToolbar from "./CustomToolbar";
import AddUserDialog from "./AddUserDialog";
import DeleteUserDialog from "./DeleteUserDialog";
import UserToModelsDialog from './UserToModelsDialog';
// import CircularProgress from "./EditUserDialog";
//import { flattenProp } from "recompose";
import Loading from "../../components/Loading";
import AccessIcon from "@material-ui/icons/CheckCircleOutline";
import NoAccessIcon from "@material-ui/icons/HighlightOff";
import Avatar from '@material-ui/core/Avatar';


//import LadleChip from "../components/LadleChip/LadleChip";

const styles = theme => ({
  access:{
    color: theme.palette.green,
    margin:0,
    padding:5,
  },
  config:{
    margin:0,
    padding:5,
  },
  noaccess:{
    color: theme.palette.red,
    margin:0,
    padding:5,
  },
  main: {
    marginBottom: theme.spacing(1),
  },
  root: {
    width: "100%",
    //height: "100%"
  },
});


class UsersList extends Component {

  state = {
    openAddDialog: false,
    openEditDialog: false,
    openDeleteDialog: false,
    openUserToModelDialog: false,
    dataTableUsers: null,
    allModels: [],
    userList:[],
    usersToModels:[],
    loadingAccess: false,
    rowsDeleted:{data:[]}
  }

  componentDidMount(){
    this.getUsersList();
  }

  getUsersList(){
    axios
    .get("/get_users")
    .then(response => {
      this.setState({
        userList: response.data,
      });
      this.generateUserListData();
    })
    .catch(error => {
      this.props.enqueueSnackbar("Error geting users list", {
        variant: "error"
      });
    });

  }

  generateUserListData(){
    axios
    .get("/get_all_models")
    .then(response => {
      this.setState({
        allModels: response.data.allModels,
        loading: false
      });
    })
    .catch(error => {
      this.props.enqueueSnackbar("Error geting models list", {
        variant: "error"
      });
    });

    this.state.userList.forEach((row)=>{
      // console.log('print',row);
      axios.post('/get_list_models',{email:'all'})
      .then((resp)=>{
        this.setState({usersToModels:resp.data.models})
      })
      .catch((error)=>{
        this.props.enqueueSnackbar("Error geting user to models list", {
          variant: "error"
        });
      })
    })

    let dataAux = [];
    let listAccess = []
    if (this.state.userList.length) {
      this.state.userList.map(dato => {
        const unEstablecimiento = [
          dato.image ? dato.image: null,
          dato.displayName,
          dato.email,
          dato.company,
          dato.role,
          dato.access,
          dato.id,
        ];
        listAccess.push(false)
        dataAux.push(unEstablecimiento);
        return dato;
      });

      this.setState({
        dataTableUsers: dataAux,
        loadingAccess: listAccess
      });
    }
  }

  handleOpenAddDialog = () => {
    this.setState({
      openAddDialog: true
    })
  }

  handleOpenDeleteDialog = () => {
    this.setState({
      openDeleteDialog: true
    })
  }

  handleCloseDialog = (reload) => {
    this.setState({
      openAddDialog: false,
      openEditDialog: false,
      openDeleteDialog: false,
      openUserToModelDialog: false,
      snackbarOpen: false,
    })
    if (reload===true) {
      this.getUsersList()
    }
  }

  handleAllowedModels = (user) => {
    let userAux = this.state.dataTableUsers[user.currentTarget.value];
    let actual = this.state.usersToModels.filter(v=>v.email===userAux[2]);
    this.setState({openUserToModelDialog:true,user_to_edit: userAux, actual_models:actual});
  }

  editAcceso = (value,user,id) => {

    const userId = id.currentTarget.value;

    let newloading = [...this.state.loadingAccess];
    newloading[userId] = true

    this.setState({loadingAccess:newloading})

    // console.log('usuari a update',user);
    const input = {
      id: user[6],
      access: value,
    }
    axios.post('/acceso_usuario', input)
    .then((resp)=>{
      //console.log(resp)
      let newloading = [...this.state.loadingAccess];
      newloading[userId] = false

      this.componentDidMount()

      setTimeout(function() {
        this.setState({loadingAccess:newloading})
      }.bind(this), 2000);
      // this.setState({loadingAccess:newloading})
      // console.log("Se ha dado acceso al usuario")
    })
    .catch((error)=>{
      let newloading = [...this.state.loadingAccess];
      newloading[userId] = false

      this.setState({loadingAccess:newloading})
      // console.log("ERROR dando acceso al usuario")
    })
  }

  handleDeleteUser = (e) => {
    this.setState({openDeleteDialog:false})
    let ids = []
    this.state.rowsDeleted.data.forEach(doc => {
      ids.push(this.state.dataTableUsers[doc.dataIndex][6])
    });


    const input = {ids:ids}
    if (ids.length>1){
      this.props.enqueueSnackbar("Deleting users", {
        variant: "info"
      });
    }
    else{
      this.props.enqueueSnackbar("Deleting user", {
        variant: "info"
      });
    }

    axios.post('/user/delete', input)
    .then((resp)=>{
      if (ids.length>1){
        this.props.enqueueSnackbar("Users deleted correctly", {
          variant: "success"
        });
      }
      else{
        this.props.enqueueSnackbar("User deleted correctly", {
          variant: "success"
        });
      }
      this.componentDidMount()
    })
    .catch((error)=>{
      if (ids.length>1){
        this.props.enqueueSnackbar("Error deleting users. Please try again later.", {
          variant: "error"
        });
      }
      else{
        this.props.enqueueSnackbar("Error deleting user. Please try again later.", {
          variant: "error"
        });
      }
      console.log("ERROR eliminando usuario",error)
    })
  }

  render(){

    const { classes } = this.props;

    // console.log('classes',this.state);

    const options = {
      filterType: 'checkbox',
      // responsive: 'scroll',
      fixedHeader: false,
      filter: false,
      print: false,
      viewColumns: false,
      downloadOptions: {
        filename: 'users.csv',
        separator: ';',
      },
      onRowsDelete: (rowData) =>{
        this.setState({openDeleteDialog:true,
                       rowsDeleted:rowData})
      },
      customToolbar: () => {
        return (
          <CustomToolbar handleOpen={this.handleOpenAddDialog}/>
        );
      },
    };

    const columns = [
      {
        name: "",
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            // console.log('MATE',tableMeta);
            return(
              <Avatar
                src={value}
              >
              {value?'': tableMeta.rowData ? tableMeta.rowData[1].split(' ').map((word) => word[0]).join(''):''}
              </Avatar>
            )
          }
        }
      },
      {
       name: "Name",
       options: {
        filter: true,
        sort: true,
       }
      },
      {
        name: "Email",
        options: {
         filter: true,
         sort: true,
        }
      },
      {
        name: "Company",
        options: {
         filter: true,
         sort: true,
        }
      },
      {
       name: "Role",
       options: {
        filter: true,
        sort: true,
       }
      },
      {
        name: "Access",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta,updateValue) => {
            // console.log('table meta',tableMeta);
            return(
              <>
              { this.state.loadingAccess[tableMeta.rowIndex] ? (
                <IconButton disabled={true} className={classes.config}>
                  <CircularProgress style={{padding:0,margin:'auto',height:20,width:20}}/>
                </IconButton>) : (
                  <Tooltip title={value ? "Click to remove access" : "Click to give access"}>
                    <IconButton  value={tableMeta.rowIndex} onClick={this.editAcceso.bind(this.value,!value,tableMeta.rowData)} className={value ? classes.access : classes.noaccess}>
                      {value ? <AccessIcon/> : <NoAccessIcon/>}
                    </IconButton>
                  </Tooltip>
                )
              }
              </>
            )
          }
        }
       },
     ];

     columns.push({
       name: "Manage Models Access",
       options: {
         filter: false,
         sort: false,
         customBodyRender: (value, tableMeta, updateValue) => {
           return(
             <>
             <Tooltip title="Allowed Models">
               <IconButton className={classes.config} aria-label="Allowed Models" value={tableMeta.rowIndex} onClick={this.handleAllowedModels.bind(tableMeta.rowIndex)}>
                 <SettingsIcon />
               </IconButton>
             </Tooltip>
             </>
           )
         }
       }
     })

    return (
      <div className={classes.main}>
      {
        this.state.dataTableUsers ? (
        <>
         <MUIDataTable
          data={this.state.dataTableUsers}
          columns={columns}
          options={options}
        />
        <AddUserDialog
          open={this.state.openAddDialog}
          handleClose={this.handleCloseDialog}
        />
        <DeleteUserDialog
          vars={this.state.rowsDeleted.data.length}
          handleDeleteUser={this.handleDeleteUser}
          open={this.state.openDeleteDialog}
          handleClose={this.handleCloseDialog}
        />

        {
          this.state.user_to_edit ? (
            <UserToModelsDialog
              open={this.state.openUserToModelDialog}
              handleClose={this.handleCloseDialog}
              actual_models={this.state.usersToModels}
              user_to_edit={this.state.user_to_edit}
              models_list={this.state.allModels}
            />
          ) : null
        }
        {
          // <EditUserDialog
          //   open={this.state.openEditDialog}
          //   handleClose={this.handleCloseDialog}
          //   user_to_edit={this.state.user_to_edit}
          // />
        }
      </>
        ) : (<Loading/>)
      }
      </div>
    );
  }
}

UsersList.propTypes = {
  user: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
};

function usersListState(state) {
  return {
    // models: state.models,
    user: state.user
  };
}

export default compose(
  withStyles(styles),
  withSnackbar,
  connect(
    usersListState,
    null
  )
)(UsersList);
