// Imports
import React, { Component } from 'react'
//import { connect } from 'react-redux'
import PropTypes from 'prop-types'
// import axios from "axios";
import { withStyles } from '@material-ui/core/styles';
// import roles from '../../config/roles';
// import { connect } from "react-redux";
import { compose } from "recompose";
import {  withSnackbar } from "notistack";
// UI Imports
import { Button, Typography } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

const styles = theme => ({
  root: {
    maxWidth: '500px',
    margin: '0 auto',
    width: '60%',
    paddingTop: '5px',
  },
  textField: {
    marginTop: 15,
  },

  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
  },
  formControl: {
    marginTop: theme.spacing(1),
  },
  formControlLabel: {
    marginLeft: 0,
    marginRight: 0,
  },
  formSwitch: {
    marginTop: theme.spacing(1),
  },
});

class DeleteUserDialog extends Component {
  state = {
    isLoading: false,
    isLoggingIn: false,
    userEdited: false,
    backArrowClicked: false,
    display: 'content',
  }

  onSubmit (event) {
    event.preventDefault();
    this.props.handleDeleteUser();
  }

  render () {
    
    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.props.handleClose}
          aria-labelledby="form-dialog-title"
          //maxWidth="xl"
          fullWidth={true}
        >
          <DialogTitle style={{paddingBottom:'0px'}}>{"Delete User"}</DialogTitle>
          <form id="form-tweet" onSubmit={this.onSubmit.bind(this)}>
            <DialogContent style={{paddingTop:'20px', paddingBottom: '5px'}}>
              <Typography>Are you sure you want to delete {this.props.vars>1 ? 'these users? ': 'this user? '}</Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.props.handleClose}>
                Cancel
              </Button>
              <Button type="submit" color="primary" autoFocus>
                Delete
              </Button>
          </DialogActions>
          </form>
        </Dialog>
      </div>
    )
  }
}

DeleteUserDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
}

export default compose(
  withStyles(styles),
  withSnackbar
)(DeleteUserDialog);
