import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
//import registerServiceWorker from './registerServiceWorker';
import { BrowserRouter } from "react-router-dom";
import rootReducer from './reducers'
import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import thunk from 'redux-thunk'
import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
//import WithTheme from './WithTheme';

//DESCOMENTAR DE CLIENT (index.js) Y SERVER (server.js) PARA USAR SENTRY!!!!!!!
// import * as Sentry from '@sentry/browser';
// Sentry.init({dsn: 'http://6ef9e2ff25ce4590beaf7423e9c4971d@35.190.163.116:9000/20'})

const store = createStore(
  rootReducer,

  composeWithDevTools(
    applyMiddleware(thunk)
  )
)

const theme = createMuiTheme({
  palette: {
    primary: {
      main:'#303030',
    },
    secondary: {
      main:'#f0b823',
    },
    terciary: {
      main:'#DB4437',
    },
    quaternary: {
      main:'#F4B400',
    },
    green: '#0F9D58',
    yellow: '#F4B400',
    blue: '#4285F4',
    red: '#DB4437',
    //background: red,
    error: {
      main: '#ff0000',
    },
  },
  overrides:{
    MuiStepIcon:{
      root:{
        '&$completed':{
          color:'#0F9D58'
        }
      }
    },
    MUIDataTableBodyCell:{
      root:{
        paddingTop:10,
        paddingBottom:10,
        textAlign: 'center',
      },
    },
    MuiAvatar:{
      root:{
        width:30,
        height:30,
      },
    },
    MUIDataTableHeadCell:{
      root:{
        textAlign:'center',
        // marginLeft:10,
      },
      sortAction:{
        display:'contents',
      },
      toolButton:{
        display:'contents',
      },
    },
    MuiExpansionPanelDetails:{
      root:{
        padding:'0px',
      },
    },
    MuiList:{
      padding:{
        paddingBottom:'0px',
      },
    },
    MuiSelect:{
      selectMenu:{
        paddingTop: '6px',
        paddingBottom: '6px',
        height:'20px',
      },
    },
    MuiOutlinedInput:{
      root:{
        height:'32px',
      },
      input:{
        textAlign:'center',
      }
    },
    MuiFormControl:{
      marginNormal:{
        marginTop:'0px',
        marginBottom:'0px',
      },
    },
    MuiDialog:{
      paperWidthSm:{
        // minWidth:'75%',
      },
      paperScrollPaper:{
        maxHeight: 'calc(100% - 40px)',
      }
    },
    MuiPaper:{
      root:{
        // backgroundColor:'#E0E0E0',
      },
    },
  },
  typography: { useNextVariants: true },
});

ReactDOM.render(
  <MuiThemeProvider  theme={theme}>
    <Provider store={store}>
      <SnackbarProvider maxSnack={10} transitionDuration={{ exit: 380, enter: 400 }}>
        <BrowserRouter >
          <App />
        </BrowserRouter>
      </SnackbarProvider>
    </Provider>
  </MuiThemeProvider>
  , document.getElementById('root')
);
//registerServiceWorker();
