import { withStyles } from "@material-ui/core/styles";
import React, { Component } from "react";
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import axios from "axios";
import moment from 'moment';
import Loading from '../Loading';
import Tooltip from '@material-ui/core/Tooltip';

const styles = {
  card: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
  cardContent: {
    paddingTop: '5px',
    paddingBottom: '5px',
  },
  pending: {
    paddingTop: '5px',
    paddingBottom: '5px',
    backgroundColor: "grey"
  },
};

class GalleryThumbnail extends Component {
  state = {
    imageURL: "",
    preloadImageUrl: "",
    texto: ""
  };

  handleImageURLChange = imageURL => {
    const newState = { ...this.state };
    newState.imageURL = imageURL;
    // console.log('image urls',this._mounted,imageURL);
    if (this._mounted === true) this.setState(newState);
  };
  handleThumbURLChange = preloadImageUrl => {
    const newState = { ...this.state };
    newState.preloadImageUrl = preloadImageUrl;
    if (this._mounted === true) this.setState(newState);
  };

  componentDidMount() {
    this._mounted = true;
    if (this.props.file) {
      // const filepath = "/images-to-predict/" + this.props.file;
      const filepathThumb = "thumbnails/" + this.props.thumb;
      axios
        .get("/imagen?filename=" + filepathThumb)
        .then(response => {
          if (this._mounted === true) this.handleThumbURLChange(response.data);
        })
        .catch(error => {
          // error;
        });
      // axios
      //   .get("/imagen?filename=" + filepath)
      //   .then(response => {
      //     if (this._mounted === true) this.handleImageURLChange(response.data);
      //   })
      //   .catch(error => {
      //     // error;
      //     console.log('ERRRRORRRRR',error);
      //   });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.file !== prevProps.file) {
      // const filepath = "/images-to-predict/" + this.props.file;
      const filepathThumb = "thumbnails/" + this.props.thumb;
      axios
        .get("/imagen?filename=" + filepathThumb)
        .then(response => {
          if (this._mounted === true) this.handleThumbURLChange(response.data);
        })
        .catch(error => {
          // error;
        });
      // axios
      //   .get("/imagen?filename=" + filepath)
      //   .then(response => {
      //     if (this._mounted === true) this.handleImageURLChange(response.data);
      //   })
      //   .catch(error => {
      //     // error;
      //   });
    }
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  render() {
    const { classes } = this.props;
    // console.log('thumb props',this.props);
    return (
      <Tooltip title={this.props.status==='pending'?'Prediction pending':this.props.status==='failure'?'Error making prediction':this.props.status==='success'?"View Prediction Details":''}>
      <Card className={classes.card}>
        <CardActionArea
          onClick={this.props.onClick}>
          {this.state.imageURL ==='' ?
            this.state.preloadImageUrl === '' ?
            (
              <Loading/>
            )
            :
            (<CardMedia
              className={classes.media}
              image={this.state.preloadImageUrl}
            />)
            :
            (<CardMedia
              className={classes.media}
              image={this.state.imageURL}
            />)}
          <CardContent className={this.props.status!=='success'?classes.pending:classes.cardContent}>
            <>
            <Typography variant="overline" style={{marginBottom:'0'}}>
              {
                this.props.image_id
              }
            </Typography>
            <Typography variant="overline" style={{marginBottom:'0'}}>
              {
                this.props.models.find(
                  model => model.id === this.props.model
                ).modelName
              }
            </Typography>
            <Typography variant="overline" style={{marginBottom:'10px'}}>
              {moment(this.props.label).format("DD/MM/YYYY H:mm:ss")}
            </Typography>
            </>
          </CardContent>
        </CardActionArea>
      </Card>
      </Tooltip>
    );
  }
}

export default withStyles(styles)(GalleryThumbnail);
