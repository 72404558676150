import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Loading from "./Loading";
import Typography from '@material-ui/core/Typography';
import Grid from "@material-ui/core/Grid";
import Paper from '@material-ui/core/Paper';
import Img from 'react-image';

import heureka from "./Heureka.jpg"

// const IMAGE_BASE_URL = "/images/Heureka.jpg";

const styles = theme => ({
    mainFeaturedPost: {
      backgroundColor:'transparent',
      boxShadow: 'unset',
      color: theme.palette.common.white,
      marginBottom: theme.spacing(4),
      marginLeft: theme.spacing(4),
      marginRight: theme.spacing(4),
      marginTop: theme.spacing(6),
      paddingTop: theme.spacing(2),
    },
    mainFeaturedPostContent: {
      padding: `${theme.spacing(2)}px`,
      [theme.breakpoints.up('md')]: {
        paddingRight: 0,
      },
      justifyContent:'center',
      display:'flex',
    },
    root:{
      backgroundRepeat: 'no-repeat',
      backgroundAttachment: 'scroll',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      width: "100%",
      height: "100vh",
    },
  });

function Layout(props) {
  const { classes } = props;
  return (
    <div className={classes.root}>
      {<Img src={heureka} alt="Logo" height="50px" style={{width:'100%',height:'100vh'}}>
       </Img>}
      <div style={{ backgroundColor: "rgba(0, 0, 0, 0.6)", height:'100vh', position:'fixed',top:0,left:0,width:'100%'}}>
        <div style={{paddingTop: 200}}>
          <Paper className={classes.mainFeaturedPost}>
            <Grid container>
              <Grid item md={12}>
                <div className={classes.mainFeaturedPostContent}>
                  <Typography component="h1" variant="h3" color="secondary" style={{fontFamily:'Saira Semi Condensed', fontWeight: 600, fontSize: 60}}>
                    Getting the most amazing AI
                  </Typography>
                  {/* <Typography variant="h5" color="inherit" >
                    Multiple lines of text that form the lede, informing new readers quickly and
                    efficiently about what&apos;s most interesting in this post&apos;s contents…
                  </Typography> */}
                </div>
              </Grid>
              <Grid item md={12}>
                <div className={classes.mainFeaturedPostContent}>
                  <Loading />
                </div>
              </Grid>
            </Grid>
          </Paper>
        </div>
      </div>
    </div>
  );
}

Layout.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Layout);
