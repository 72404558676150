import React from 'react';
//import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
//import TextField from '@material-ui/core/TextField';
//import FormControlLabel from '@material-ui/core/FormControlLabel';
//import Checkbox from '@material-ui/core/Checkbox';
//import Button from '@material-ui/core/Button';
//import Input from '@material-ui/core/Input';
//import OutlinedInput from '@material-ui/core/OutlinedInput';
//import FilledInput from '@material-ui/core/FilledInput';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
//import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

function ChooseModel(props) {
  const models = props.models.models.sort((a, b) => (a.modelName > b.modelName ? 1 : -1))
  return (
    <React.Fragment>
      <form>
        <Typography variant="h6" gutterBottom>
          {props.loading ? "Loading Models list" : "Choose a model to use"}
        </Typography>
        {props.loading ? null : (
          <FormControl style={{width:'75%'}}>
            <InputLabel htmlFor="model-simple">Model</InputLabel>
            <Select
              value={props.model.modelName}
              onChange={props.handleSetObject('model','id')}
              inputProps={{
                name: 'model',
                id: 'model-simple',
              }}
            >
              {models.map(row=>{
                return (<MenuItem value={row.modelName} key={row.modelName}>{row.modelName}</MenuItem>)
              })}
            </Select>
          </FormControl>
        )}
      </form>
    </React.Fragment>
  );
}

export default ChooseModel;
