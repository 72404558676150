import axios from "axios";
import { FETCH_USER } from "./types";

export const fetchUser = () => async dispatch => {
  try {
    const res = await axios.get("/api/current_user", {
      headers: { Accept: "application/json" }
    });
    dispatch({ type: FETCH_USER, payload: res.data });
  } catch (error) {
    console.log("error loading user ",error)
    window.setInterval(fetchUser,3000)
  }
};
