import React from "react";
import PropTypes from "prop-types";
import { Switch, Route, Link } from "react-router-dom";
import { withRouter } from "react-router";
import axios from "axios";
import { connect } from "react-redux";
import { compose } from "recompose";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import { fetchModelsList } from '../../actions/models'

import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import CloseIcon from "@material-ui/icons/Close";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Avatar from "@material-ui/core/Avatar";
import Img from 'react-image'


import Inicio from "../Inicio/Inicio";
import Modelos from "../Modelos/Modelos";
import Galeria from "../Galeria/Galeria";
import EdgeConfiguration from "../Edge/Configuration";
import VideoStream from "../VideoStream/VideoStream";
import UsersList from "../UsersAdmin/UsersList";
import NuevaPrediccion from "../NuevaPrediccion/NuevaPrediccion";
import NewTrain from "../NewTrain/NewTrain";
import Login from "../../components/Login";
import StickyFooter from "../../components/StickyFooter";
import PageNotFound from "../PageNotFound/PageNotFound"
import { listItems } from "../../components/Lists/ListItems";
//import logo from "./PervasiveLogo.png";
import logo from "./PervasiveLogoBlanco.png";

const IMAGE_BASE_URL = "/images/Heureka.jpg";

const drawerWidth = 200;

const styles = theme => ({
  root: {
    display: "flex",
    //height: "calc(100vh - 36px)",
  },
  backgroundStyle: {
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'scroll',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    width: "100%",
    height: "calc(100vh - 100px)",
    backgroundImage: `url(${IMAGE_BASE_URL})`,
  },
  papermain: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary
  },
  papersmall: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary
  },
  toolbar: {
    paddingRight: 24 // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    height:'64px'
  },
  appBarShift: {
    marginLeft: drawerWidth,
    [theme.breakpoints.down("sm")]: {
      marginLeft: '100vw'
    },
    width: `calc(100% - ${drawerWidth}px)`,
    [theme.breakpoints.down("sm")]: {
      width: '0px'
    },
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    height:'64px'
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36
  },
  menuButtonHidden: {
    display: "none"
  },
  title: {
    flexGrow: 1,
    color:'#f0b823',
    fontWeight: 500,
    fontFamily: "Saira Semi Condensed",
  },
  drawerPaper: {
    position: "relative",

    width: drawerWidth,
    [theme.breakpoints.down("sm")]: {
      width: '100vw'
    },
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(8)
    },
    [theme.breakpoints.down("sm")]: {
      width: theme.spacing(0)
    }
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    overflow: "auto"
  },
  chartContainer: {
    //marginLeft: -22,
  },
  tableContainer: {
    height: 320
  },
  imagegran: {
    width: "90%"
  },
  imagesmall: {
    width: "80%"
  },
  textplate: {
    textAlign: "left",
    padding: "0px"
  }
});

class Framework extends React.Component {
  state = {
    badge: 0,
    open: false,
    mainImage: "",
    imageList: ["none", "none"],
    resultsList: [],
    datos: [],
    auth: true,
    anchorEl: null,
    logout: false
  };

  mapSection = () =>{
    const pathname = this.props.location.pathname.split("/")

    switch(pathname[1]){
      default:
        return "Heureka Prediction Platform"
    }
  }

  renderLogin() {
    //console.log('render App Login');
    //console.log('User',this.props.user);
    switch (this.props.user) {
      case null:
        return (
          <MenuItem onClick={this.handleClose} href="/auth/google">
            Login with Google
          </MenuItem>
        );
      case false:
        return (
          <MenuItem onClick={this.handleClose} href="/auth/google">
            Login with Google
          </MenuItem>
        );
      default:
        // <a  href="/api/logout">Logout</a >
        return [
          <ListItem style={{ outline: "none" }} key="userDisplay">
            {this.props.user.displayName}
          </ListItem>,
          <MenuItem
            key="logout"
            onClick={this.handleCloseLogout}
            component={Link}
            to="/"
          >
            Logout
          </MenuItem>
        ];
    }
  }

  handleLogin = () => {
    const newState = { ...this.state };
    newState.auth = true;
    this.setState(newState);
  };

  handleDrawerOpen = () => {
    const newState = { ...this.state };
    newState.open = true;
    this.setState(newState);
  };

  handleDrawerClose = () => {
    const newState = { ...this.state };
    newState.open = false;
    this.setState(newState);
  };

  handleChange = event => {
    this.setState({ auth: event.target.checked });
  };

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  logout() {
    axios.get("/api/logout", {
      headers: { Accept: "application/json" }
    })
    .then((response) => {window.location.href = "/";})
  }

  handleCloseLogout = () => {
    const newState = { ...this.state };
    newState.anchorEl = null;
    this.logout();
    newState.logout = true;
    this.setState(newState);
  };

  componentDidMount(){
    if (this.props.user){
      if (this.props.user.access===true){
        this.props.fetchModelsList(this.props.user)
      }
    }
  }

  handleCloseMenu = () =>{
    if (window.innerWidth<600){
      this.setState({open:false})
    }
  }

  render() {
    const { classes } = this.props;
    const { auth, anchorEl } = this.state;
    const open = Boolean(anchorEl);

    if (this.props.user.access===false) {
      return <Login />;
    }
    return (
      <React.Fragment>
        <CssBaseline />
        <div className={classes.root}>
          <AppBar
            position="absolute"
            className={classNames(
              classes.appBar,
              this.state.open && classes.appBarShift
            )}
          >
            <Toolbar
              disableGutters={!this.state.open}
              className={classes.toolbar}
              style={this.state.open && window.innerWidth<600 ? {display:'None'} : null}
            >
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={this.handleDrawerOpen}
                className={classNames(
                  classes.menuButton,
                  this.state.open && classes.menuButtonHidden
                )}
              >
                <MenuIcon />
              </IconButton>
              {<Img src={logo} alt="Logo" height="50px">
              </Img>}
              <Typography
                variant="h5"
                color="inherit"
                noWrap
                className={classes.title}
              >
                {this.mapSection()}
              </Typography>
              {auth && (
                <div>
                  <IconButton
                    aria-owns={open ? "menu-appbar" : null}
                    aria-haspopup="true"
                    onClick={this.handleMenu}
                    color="inherit"
                  >
                    {this.props.user.image ? (
                      <Avatar
                        alt={this.props.user.displayName}
                        src={this.props.user.image}
                        className={classes.avatar}
                      />
                    ) : (
                      <AccountCircle />
                    )}
                  </IconButton>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right"
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right"
                    }}
                    open={open}
                    onClose={this.handleClose}
                  >
                    {this.renderLogin()}
                  </Menu>
                </div>
              )}
            </Toolbar>
          </AppBar>
          <Drawer
            variant="permanent"
            classes={{
              paper: classNames(
                classes.drawerPaper,
                !this.state.open && classes.drawerPaperClose
              )
            }}
            open={this.state.open}
          >
            <div className={classes.toolbarIcon}>
              <IconButton onClick={this.handleDrawerClose} style={window.innerWidth<600 ? {margin: '8px'} : null}>
                {
                  window.innerWidth <600 ?
                  <CloseIcon />
                  :
                  <ChevronLeftIcon />
                }

              </IconButton>
            </div>
            <Divider />
            <List>{listItems(this.props,this.handleCloseMenu)}</List>
            <Divider />
          </Drawer>
          <main className={classes.content}>
            <div className={classes.appBarSpacer} />
            <div className={classes.backgroundStyle}>
            <div style={{backgroundColor: "rgba(0, 0, 0, 0.6)",width:"100%",height:"calc(100vh - 100px)"}}>
            <div
              style={{
                height: "calc(100vh - 100px)",
                width: "100%",
                overflowX: "hidden",
                overflowY: "auto",
                padding: "20px"
              }}
            >
              <Switch>
                <Route path="/" exact component={Inicio} />
                <Route path="/inicio" exact component={Inicio} />
                {
                  (this.props.user.role==='Admin')||(this.props.user.role==='User') ? (<Route path="/models" exact component={Modelos} />) : null
                }
                {
                  (this.props.user.role==='Admin')||(this.props.user.role==='User') ? (<Route path="/gallery" exact component={Galeria} />) : null
                }
                {
                  (this.props.user.role==='Admin')||(this.props.user.role==='User') ? (<Route path="/new_prediction" exact component={NuevaPrediccion}/>) : null
                }
                {
                  (this.props.user.role==='Admin')||(this.props.user.role==='User') ? (<Route path="/new_prediction/:id" exact component={NuevaPrediccion}/>) : null
                }
                {
                  (this.props.user.company==="Pervasive Technologies" && this.props.user.role==='Admin') || this.props.user.role==='Stream' ? (<Route path="/video_stream" exact component={VideoStream} />) : null
                }
                {
                  this.props.user.company==="Pervasive Technologies" && this.props.user.role==='Admin' ? (<Route path="/edge_configuration" exact component={EdgeConfiguration} />) : null
                }
                {
                  this.props.user.company==="Pervasive Technologies" && this.props.user.role==='Admin' ? (<Route path="/new_train" exact component={NewTrain} />) : null
                }
                {this.props.user.company==="Pervasive Technologies" && this.props.user.role==='Admin' ? (<Route path="/users" exact component={UsersList}/>) : null}
                <Route component={PageNotFound} />
              </Switch>
              
            </div>
            </div>
            </div>
          </main>{" "}
        </div>
        <StickyFooter/>
      </React.Fragment>
    );
  }
}

Framework.propTypes = {
  user: PropTypes.object.isRequired,
  models: PropTypes.object.isRequired,
  fetchModelsList: PropTypes.func.isRequired,
};

function containerState(state) {
  return {
    models: state.models,
    user: state.user
  };
}

export default compose(
  withStyles(styles),
  withRouter,
  connect(
    containerState,
    {fetchModelsList}
  )
)(Framework);
