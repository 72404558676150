import React from "react";
//import axios from "axios";
//import PropTypes from "prop-types";
//--------------------------------

//--------------------------------

//--------------------------------
import red from "@material-ui/core/colors/red";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

const styles = theme => ({
  mainFeaturedPost: {
    backgroundColor: theme.palette.grey[800],
    color: theme.palette.common.white,
    marginBottom: theme.spacing(4),
    backgroundColor:'transparent',
    boxShadow: 'unset',
    marginTop: theme.spacing(10)

  },
  mainFeaturedPostContent: {
    padding: `${theme.spacing(6)}px`,
    [theme.breakpoints.up('md')]: {
      paddingRight: 0,
    },
  },
  loading: {
    width: "100%",
    height: "80vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  paper: {
    ...theme.mixins.gutters(),
    marginBottom: "10px"
  },
  paper2: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    whiteSpace: "nowrap",
    marginBottom: theme.spacing(1)
  },
  slider: {
    //marginRight:'40px',
    height: "100%"
  },
  rootGridList: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden"
    //backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    flexWrap: "nowrap",
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    width: "100%",
    transform: "translateZ(0)",
    enum: "auto"
  },
  title: {
    //color: theme.palette.primary.light,
  },
  titleBar: {
    //background: "transparent",
    background:
      "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)"
  },
  icon: {
    color: red
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    textAlign: "center",
  },
  menu: {
    width: 200
  },
  gridContainer: {
    flexGrow: 1
  },
  paper3: {
    textAlign: "center"
  },
});

class Analytics extends React.Component {
  state = {

  };

  componentDidMount() {
  }

  render() {
    const { classes } = this.props;

    return (
      <div>
        <Paper className={classes.mainFeaturedPost}>
          <Grid container>
            <Grid item md={12}>
              <div className={classes.mainFeaturedPostContent}>
                <Typography component="h1" variant="h3" color="secondary" style={{fontFamily:'Saira Semi Condensed', fontWeight: 600, fontSize: 60}}>
                  Pervasive Technologies
                </Typography>
                <Typography variant="h5" color="inherit" style={{fontFamily:'Saira Semi Condensed', fontWeight: 400}}>
                  AI solutions to improve your operations
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Paper>
      </div>
    );
  }
}

// Analytics.propTypes = {
//   // user: PropTypes.object.isRequired,
//   // classes: PropTypes.object.isRequired,
// };

export default withStyles(styles)(Analytics);
