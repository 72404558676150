// Imports
import React, { Component } from 'react'
import axios from "axios";
import PropTypes from 'prop-types'
//import { compose } from 'recompose'
import { withStyles } from '@material-ui/core/styles';
// import roles from '../../config/roles';
// import { connect } from "react-redux";
import { compose } from "recompose";
import {  withSnackbar } from "notistack";
// UI Imports
import { Button,
         List,
         ListItem,
        ListItemIcon,
        ListItemText,
        Switch,
        ListItemSecondaryAction } from '@material-ui/core'
import ODIcon from '@material-ui/icons/LocationSearching';
import OCRIcon from '@material-ui/icons/Translate';
import PLIcon from '@material-ui/icons/ViewCompact';
import UNKIcon from '@material-ui/icons/Help';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
// import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
// import Autocomplete from './Autocomplete';
// import ChipSelection from '../../components/ChipSelection';

const styles = theme => ({
  root: {
    maxWidth: '500px',
    margin: '0 auto',
    width: '100%',
    paddingTop: '5px',
  },
  textField: {
    marginTop: 15,
  },

  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
  },
  formControl: {
    marginTop: theme.spacing(1),
  },
  formControlLabel: {
    marginLeft: 0,
    marginRight: 0,
  },
  formSwitch: {
    marginTop: theme.spacing(1),
  },
});

class UserToModelDialog extends Component {
  state = {
    models_list: null,
  }

  onSubmit (event) {
    event.preventDefault();
    let models = this.state.models_list.filter(v=>v.email===this.props.user_to_edit[2])[0].models.filter(v=>v.access===true).map(elem=>{return elem.id})
    axios.post('/update_list_models',{email:this.props.user_to_edit[2],models:models})
    .then((resp)=>{
      this.props.handleClose(true)
      this.props.enqueueSnackbar("User model list updated correctly", {
        variant: "success"
      });
    })
    .catch((error)=>{
      console.log(error);
      this.props.enqueueSnackbar("Error updating user models list", {
        variant: "error"
      });
    })
  }

  handleToggle = value => () => {
    const currentIndex = this.state.models_list;
    const updateModels = currentIndex.filter(v=>v.email===this.props.user_to_edit[2])[0].models;
    updateModels.forEach(row=>{
      if (row.id===value.id){
        row.access = !row.access
      }
    })
    this.setState({models_list:currentIndex})
  };

  componentDidMount(){
    let models_list = [];
    let allowed = {};
    // For each user:
    this.props.actual_models.forEach(row =>{
      allowed[row.email] = [];
      // For each model the user has access we add it on allowed for that user:
      row.models.forEach(elem =>{
        allowed[row.email].push(elem)
      })
      // For all the possible models:
      let userModels = [];
      this.props.models_list.forEach(elem=>{
        userModels.push({...elem})
      })
      userModels.forEach(elem =>{
        //
        elem.access = allowed[row.email].includes(elem.id)
      })
      models_list.push({email:row.email,models:userModels})
    })
    this.setState({models_list:models_list})
  }

  render () {
    let elements = null

    if (this.state.models_list){
      if (this.state.models_list.filter(v=>v.email===this.props.user_to_edit[2])[0].models){
        elements = (
          <List>
          {
            this.state.models_list.filter(v=>v.email===this.props.user_to_edit[2])[0].models.map((row,idx)=>{
              // console.log('row',row.access);
              return (<ListItem key={idx} style={{paddingTop:0, paddingBottom:0}}>
                <ListItemIcon>
                  {
                    row.type==='OD' ? (<ODIcon />) : row.type==='OCR' ? (<OCRIcon />) : row.type==='PL' ? (<PLIcon />) : (<UNKIcon />)
                  }
                </ListItemIcon>
                <ListItemText id={idx} primary={row.modelName} secondary={row.id}/>
                <ListItemSecondaryAction>
                  <Switch
                    edge="end"
                    onChange={this.handleToggle(row)}
                    checked={row.access}
                    inputProps={{ 'aria-labelledby': 'switch-list-label-wifi' }}
                  />
                </ListItemSecondaryAction>
              </ListItem>)
            })
          }
          </List>
        )
      }
    }

    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.props.handleClose}
          aria-labelledby="form-dialog-title"
          //maxWidth="xl"
          fullWidth={true}
        >
          <DialogTitle style={{paddingBottom:'0px'}}>{"Assign models to " + this.props.user_to_edit[1]}</DialogTitle>
          {elements}
          <DialogActions>
            <Button onClick={this.props.handleClose}>
              Cancel
            </Button>
            <Button type="submit" color="primary" autoFocus onClick={this.onSubmit.bind(this)}>
              Assign
            </Button>
          </DialogActions>
        </Dialog>
        {

          //   <DialogTitle style={{paddingBottom:'0px'}}>{"Assign models to User"}</DialogTitle>
          //   <form id="form-tweet" onSubmit={this.onSubmit.bind(this)}>
          //     <DialogContent style={{paddingTop:'5px', paddingBottom: '5px'}}>
          //       <Autocomplete
          //         suggestions={this.props.models_list}
          //         placeholder="Assign a model"
          //         writingNewField={this.writingNewModel}
          //         labelValue={false}/>
          //     </DialogContent>
          //     {
          //     <ChipSelection
          //                 selected={this.state.actual_models}
          //                 onChange={this.handleChange}
          //                 list={this.props.models_list}
          //                 classes={classes}
          //                 onDelete={this.handleDelete}
          //     />
          //     }
          //     <DialogActions>
          //       <Button onClick={this.props.handleClose}>
          //         Cancelar
          //       </Button>
          //       <Button type="submit" color="primary" autoFocus>
          //         Aceptar
          //       </Button>
          //   </DialogActions>
          //   </form>

        }
      </div>
    )
  }
}

UserToModelDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
}

export default compose(
  withStyles(styles),
  withSnackbar
)(UserToModelDialog);
