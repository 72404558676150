import React from "react";
// import ReactDOM from "react-dom";

//import * as cocoSsd from "@tensorflow-models/coco-ssd";
import * as tf from "@tensorflow/tfjs";
import { withStyles } from "@material-ui/core/styles";

import {
  Grid,
  Button,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  IconButton,
  ListSubheader,
  LinearProgress,
  CircularProgress
} from "@material-ui/core";

import { MobileNet } from "./mobilenet";
import Grow from "@material-ui/core/Grow";

import WarningRoundedIcon from "@material-ui/icons/WarningRounded";
import CheckCircleOutlineRoundedIcon from "@material-ui/icons/CheckCircleOutlineRounded";
import VideocamIcon from "@material-ui/icons/Videocam";
import moment from "moment";
import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

// Your web app's Firebase configuration
var firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: "infaimon-projects.firebaseapp.com",
  databaseURL: "https://infaimon-projects.firebaseio.com",
  projectId: "infaimon-projects",
  storageBucket: "infaimon-projects.appspot.com",
  messagingSenderId: "579435364092",
  appId: "1:579435364092:web:d282a50669a6c12f97a179"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

var db = firebase.firestore();

const dictClasses = [
  { label: "", color: "#000000" },
  { label: "Máscara anti-polvo", color: "#F5F5DC" },
  { label: "Protección auditiva", color: "#FFFF00" },
  { label: "Gafas", color: "#FFB6C1" },
  { label: "Cara", color: "#FFD700" },
  { label: "Persona", color: "#00FFFF" },
  { label: "Botas de protección", color: "#A52A2A" },
  { label: "Casco de protección", color: "#7CFC00" },
  { label: "Guantes", color: "#3333B0" },
  { label: "Chaleco de seguridad", color: "#9400D3" }
];

/*db.collection("users")
.get()
.then(function(doc) {
  console.log("Current data: ", doc.data());
});*/

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    position: "relative",
    minWidth: "600px"
  },
  list: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    position: "relative",
    overflow: "auto",
    maxHeight: 280,
    textAlign: "left"
  },
  listSection: {
    backgroundColor: "inherit"
  },
  ul: {
    backgroundColor: "inherit",
    padding: 0
  },
  fab1: {
    position: "absolute",
    top: "33%",
    left: "40%",
    width: "48px",
    height: "48px",
    color: "#1b1b1b",
    transform: "rotate(150deg)"
  },
  fab2: {
    position: "absolute",
    top: "14%",
    left: "7%",
    width: "48px",
    height: "48px",
    color: "#1b1b1b",
    transform: "rotate(100deg)"
  },
  fab3: {
    position: "absolute",
    top: "60%",
    left: "72%",
    width: "48px",
    height: "48px",
    color: "#1b1b1b",
    transform: "rotate(10deg)"
  },
  dropzoneImg: {
    width: "100%",
    height: "100%",
    borderRadius: "4px"
  },
  cameraIcon: {
    borderStyle: "solid",
    backgroundColor: "#a4a4a4"
  },
  error: {
    background: "#ef5350"
  },
  iconError: {
    color: "#ffb74d"
  },
  iconOk: {
    color: "#aed581"
  },
  dropzonePage: {
    paddingTop: "15px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    margin: "auto"
  },
  noMargin: {
    textAlign: "center"
  },
  canvas: {
    position: "absolute",
    top: "10px",
    bottom: "10px",
    left: "10px",
    right: "10px",
    border: "thin solid rgba(64, 64, 64, 0.25)",
    borderRadius: "10px",
    objectFit: "cover"
  },
  slider: {
    marginLeft: "15px",
    marginRight: "15px",
    padding: "22px 0px"
  },
  gridTheme: {
    backgroundColor: theme.palette.background.default
  },
  form: {
    margin: theme.spacing(3),
    marginTop: "0px",
    marginBottom: "0px",
    width: "100%"
  }
});

class Video extends React.Component {
  constructor(props) {
    super(props);
    // Don't call this.setState() here!
    this.state = {
      cameraSelected: 2,
      alerts: [],
      rawEvents: [],
      rawAlerts: [],
      events: [],
      alertDates: [],
      eventDates: [],
      predictions: [],
      loadStatus: 0
    };
    this.n = 0;
    this.oldPP = [0, 0, 0, 0];
    this.oldH = 0;
    this.ndet = 0;
    this.detectedPeople = 0;
    this.detectedHelmets = 0;
    this.centroidInside = [];
    this.videoRef = React.createRef();
    this.canvasRef = React.createRef();
    // EZEQUIEL 1
    this.oldFrameRef = React.createRef();
    // EZEQUIEL
    this.startedAt = moment().format();
    this.mounted = true;
  }

  componentDidMount() {
    this.setState({
      cameraSelected: 2,
      alerts: [],
      rawEvents: [],
      rawAlerts: [],
      events: [],
      alertDates: [],
      eventDates: [],
      predictions: [],
      loadStatus: 0
    });
    this.mounted = true;
    const component = this;
    db.collection("video_events_demo")
      .doc("events")
      .onSnapshot(function(doc) {
        const data = doc.data();
        if (component.mounted) {
          component.setState({
            rawEvents: data.list,
            events: data.list
              .map(item => ({
                ...item,
                timestamp: moment(item.timestamp.toDate()).format()
              }))
              .sort((a, b) => (a.timestamp > b.timestamp ? -1 : 1)),
            eventDates: data.list
              .reduce((dateList, item) => {
                let date = moment(item.timestamp.toDate()).format("YYYY-MM-DD");
                if (!dateList.includes(date)) {
                  dateList.push(date);
                }
                return dateList;
              }, [])
              .sort((a, b) => (a > b ? -1 : 1))
          });
        }
      });
    db.collection("video_events_demo")
      .doc("alerts")
      .onSnapshot(function(doc) {
        const data = doc.data();
        if (component.mounted) {
          component.setState({
            rawAlerts: data.list,
            alerts: data.list
              .map(item => ({
                ...item,
                timestamp: moment(item.timestamp.toDate()).format()
              }))
              .sort((a, b) => (a.timestamp > b.timestamp ? -1 : 1)),
            alertDates: data.list
              .reduce((dateList, item) => {
                let date = moment(item.timestamp.toDate()).format("YYYY-MM-DD");
                if (!dateList.includes(date)) {
                  dateList.push(date);
                }
                return dateList;
              }, [])
              .sort((a, b) => (a > b ? -1 : 1))
          });
        }
      });

    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      const webCamPromise = navigator.mediaDevices
        .getUserMedia({
          audio: false,
          video: {
            facingMode: "user"
          }
        })
        .then(stream => {
          window.stream = stream;
          this.videoRef.current.srcObject = stream;
          return new Promise((resolve, reject) => {
            this.videoRef.current.onloadedmetadata = () => {
              resolve();
            };
          });
        });

      const mobileNet = new MobileNet();
      mobileNet.load().then(value => {
        // console.log('Hem carregat el model')
        this.setState({ loadStatus: this.state.loadStatus + 1 });
        Promise.all([mobileNet, webCamPromise])
          .then(values => {
            // console.log("model", values[0], Object.keys(values[0]));
            // EZEQUIEL
            this.detectFrame(this.videoRef.current, values[0]["model"], "custom");
            // EZEQUIEL
            if (this.state.loadStatus < 3){
              this.setState({ loadStatus: this.state.loadStatus + 2 });
            }
            // this.detectFrame(this.videoRef.current, values[0]["model"]);
            // EZEQUIEL
          })
          .catch(error => {
            console.error("tenim un error!!!", error);
          });
      })
      .catch(error => {
        console.error("tenim un error en carregar el model!!!", error);
      });
    }
  }

  componentWillUnmount() {
    // console.log("unmount");
    this.mounted = false;
    this.videoRef = React.createRef();
  }

  // EZEQUIEL 1
  delayedImage = new Image();
  // EZEQUIEL

  detectFrame = (video, model) => {
    if (this.mounted) {
      // EZEQUIEL
      if (this.state.loadStatus === 2) {
        this.setState({ loadStatus: 3 });
      }
      if (this.state.loadStatus === 3) {
        this.setState({ loadStatus: 4 });
      }
      // EZEQUIEL 1
      const ctx = this.oldFrameRef.current.getContext("2d");
      ctx.drawImage(video, 0, 0, ctx.canvas.width, ctx.canvas.height);
      this.delayedImage.src = this.oldFrameRef.current.toDataURL();
      // EZEQUIEL 
      // console.log('Tenim imatge a ensenyar!')
      model
        .executeAsync(
          // EZEQUIEL 1
          // { image_tensor: tf.browser.fromPixels(video).expandDims(0) },
          // EZEQUIEL
          {
            image_tensor: tf.browser
              .fromPixels(this.oldFrameRef.current)
              .expandDims(0)
          },
          // EZEQUIEL
          [
            "num_detections",
            "detection_classes",
            "detection_boxes",
            "detection_scores"
          ]
        )
        .then(result => {
          // EZEQUIEL
          if (this.state.loadStatus < 5) {
            this.setState({ loadStatus: 5 });
          }
          // EZEQUIEL
          if (this.mounted) {
            // EZEQUIEL
            // requestAnimationFrame(() => {
            //   this.detectFrame(video, model);
            // });
            // EZEQUIEL
            let { predictions, hCentroids, pBoxes } = this.getTopKClasses(
              result,
              1
            );
            this.renderPredictions(predictions);
            this.evaluatePredictions(hCentroids, pBoxes);
            // EZEQUIEL
            requestAnimationFrame(() => {
              this.detectFrame(video, model, "custom");
            });
            // EZEQUIEL
          }
        });
      //}
      //this.ndet++;

      // model.detect(video).then(predictions => {
      //   this.renderPredictions(predictions);
      ////   this.evaluatePredictions(predictions);
      //   requestAnimationFrame(() => {
      //     this.detectFrame(video, model);
      //   });
      // });
    }
  };

  getTopKClasses = (rawOutput, topK) => {
    // const predictions = tf.tidy(() => {
    //   return tf.softmax(rawOutput);
    // });
    //console.log(rawOutput);

    const values = rawOutput.map(raw => {
      const output = raw.dataSync();
      raw.dispose();
      return output;
    });

    const numDet = parseInt(values[0][0]);
    let predictionList = [];
    let helmetCentroids = [];
    let peopleBoxes = [];
    for (let i = 0; i < numDet; i++) {
      // EZEQUIEL
      if (values[1][i] === 5) {
        peopleBoxes.push(values[2].slice(i * 4, i * 4 + 4));
      }
      if (values[1][i] === 7) {
        helmetCentroids.push(
          this.getCentroid(values[2].slice(i * 4, i * 4 + 4))
        );
      }
      predictionList.push({
        class: dictClasses[parseInt(values[1][i])].label,
        color: dictClasses[parseInt(values[1][i])].color,
        score: values[3][i],
        bbox: values[2].slice(i * 4, i * 4 + 4)
      });
      // EZEQUIEL
      // if (values[3][i] > 0.55) {
      //   if (values[1][i] === 5) {
      //     peopleBoxes.push(values[2].slice(i * 4, i * 4 + 4));
      //   }
      //   if (values[1][i] === 7) {
      //     helmetCentroids.push(
      //       this.getCentroid(values[2].slice(i * 4, i * 4 + 4))
      //     );
      //   }
      //   predictionList.push({
      //     class: dictClasses[parseInt(values[1][i])].label,
      //     color: dictClasses[parseInt(values[1][i])].color,
      //     score: values[3][i],
      //     bbox: values[2].slice(i * 4, i * 4 + 4)
      //   });
      // }
      // EZEQUIEL
    }
    //console.log(predictionList);
    /*     let predictionList = [];
    for (let i = 0; i < values.length; i++) {
      predictionList.push({value: values[i], index: i});
    }
    predictionList = predictionList
                         .sort((a, b) => {
                           return b.value - a.value;
                         })
                         .slice(0, topK);
  
    return predictionList.map(x => {
      return {label: x.index, value: x.value};
    }); */
    return {
      predictions: predictionList,
      hCentroids: helmetCentroids,
      pBoxes: peopleBoxes
    };
  };

  getCentroid = corners => [
    (corners[3] + corners[1]) / 2,
    (corners[2] + corners[0]) / 2
  ];

  renderPredictions = predictions => {
    const ctx = this.canvasRef.current.getContext("2d");

    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    // EZEQUIEL
    ctx.drawImage(this.delayedImage, 0, 0, ctx.canvas.width, ctx.canvas.height);
    // EZEQUIEL
    // Font options.
    const font = "16px sans-serif";
    ctx.font = font;
    ctx.textBaseline = "top";
    predictions.forEach(prediction => {
      // EZEQUIEL
      if (prediction.score > 0.4) {
        const x = prediction.bbox[1] * ctx.canvas.width;
        const y = prediction.bbox[0] * ctx.canvas.height;
        const width =
          (prediction.bbox[3] - prediction.bbox[1]) * ctx.canvas.width;
        const height =
          (prediction.bbox[2] - prediction.bbox[0]) * ctx.canvas.height;
        // Draw the bounding box.
        ctx.strokeStyle = prediction.color;
        ctx.lineWidth = 4;
        ctx.strokeRect(x, y, width, height);
        // Draw the label background.
        ctx.fillStyle = prediction.color;
        const textWidth = ctx.measureText(prediction.class).width;
        const textHeight = parseInt(font, 10); // base 10
        ctx.fillRect(x, y, textWidth + 4, textHeight + 4);
        // Draw the text last to ensure it's on top.
        ctx.fillStyle = "#000000";
        ctx.fillText(prediction.class, x, y);
      }
      // EZEQUIEL
      // const x = prediction.bbox[1] * ctx.canvas.width;
      // const y = prediction.bbox[0] * ctx.canvas.height;
      // const width =
      //   (prediction.bbox[3] - prediction.bbox[1]) * ctx.canvas.width;
      // const height =
      //   (prediction.bbox[2] - prediction.bbox[0]) * ctx.canvas.height;
      // // Draw the bounding box.
      // ctx.strokeStyle = prediction.color;
      // ctx.lineWidth = 4;
      // ctx.strokeRect(x, y, width, height);
      // // Draw the label background.
      // ctx.fillStyle = prediction.color;
      // const textWidth = ctx.measureText(prediction.class).width;
      // const textHeight = parseInt(font, 10); // base 10
      // ctx.fillRect(x, y, textWidth + 4, textHeight + 4);
      // // Draw the text last to ensure it's on top.
      // ctx.fillStyle = "#000000";
      // ctx.fillText(prediction.class, x, y);
      // EZEQUIEL
    });
  };

  isInside = (centroid, box) => {
    return (
      centroid[0] >= box[1] &&
      centroid[0] <= box[3] &&
      centroid[1] >= box[0] &&
      centroid[1] <= box[2]
    );
  };

  evaluatePredictions = (hCentroids, pBoxes) => {
    let predictedPeople = pBoxes.length;
    let predictedHelmets = hCentroids.length;
    let inside = hCentroids.reduce((howMany, centroid) => {
      return pBoxes.reduce(
        (isIn, box) => this.isInside(centroid, box) || isIn,
        false
      )
        ? howMany + 1
        : howMany;
    }, 0);
    this.centroidInside = [inside / predictedHelmets, ...this.centroidInside];
    if (this.centroidInside.length > 4) {
      this.centroidInside.pop();
    }

    if (
      predictedPeople !== this.detectedPeople &&
      predictedPeople !== this.oldPP[0] &&
      // EZEQUIEL
      predictedPeople !== this.oldPP[1] &&
      predictedPeople !== this.oldPP[2] &&
      predictedPeople !== this.oldPP[3]
      // EZEQUIEL
      // predictedPeople !== this.oldPP[1]
      // EZEQUIEL
    ) {
      // EZEQUIEL
      this.centroidInside = [inside / predictedHelmets];
        setTimeout(() => {
          if (predictedPeople === this.detectedPeople && predictedPeople > 0) {
            //console.log("change in people", olddp, "to", predictedPeople);
            if (
              predictedPeople > predictedHelmets ||
              this.centroidInside.length === 0 ||
              this.centroidInside.reduce((sum, value) => sum + value, 0) /
                this.centroidInside.length <
                0.8
            ) {
              this.postAlert("Invalid Acces - No Helmet");
            } else {
              this.postEvent();
            }
      // EZEQUIEL
      // this.centroidInside = [inside / predictedHelmets];
      // setTimeout(() => {
      //   if (predictedPeople === this.detectedPeople && predictedPeople > 0) {
      //     //console.log("change in people", olddp, "to", predictedPeople);
      //     if (
      //       predictedPeople > predictedHelmets ||
      //       this.centroidInside.length === 0 ||
      //       this.centroidInside.reduce((sum, value) => sum + value, 0) /
      //         this.centroidInside.length <
      //         0.8
      //     ) {
      //       this.postAlert("Invalid Access - No Helmet");
      //     } else {
      //       this.postEvent();  
      // }
      // EZEQUIEL
        }
        this.savingCent = false;
      }, 500);
      this.savingCent = true;
    }
    // EZEQUIEL
    if (predictedPeople !== this.detectedPeople) {
      this.oldPP[3] = this.oldPP[2];
      this.oldPP[2] = this.oldPP[1];
    // EZEQUIEL
      this.oldPP[1] = this.oldPP[0];
      this.oldPP[0] = this.detectedPeople;
      this.detectedPeople = predictedPeople;
    // EZEQUIEL
    }
    if (predictedHelmets !== this.detectedHelmets) {
    // EZEQUIEL
      this.oldH = this.detectedHelmets;
      this.detectedHelmets = predictedHelmets;
    // EZEQUIEL
    }
    // EZEQUIEL
  };

  // EZEQUIEL
  cycleFrames = (helmets, people) => {};
  // EZEQUIEL

  postAlert = message => {
    db.collection("video_events_demo")
      .doc("alerts")
      .set({
        list: [
          ...this.state.rawAlerts,
          {
            camera: this.state.cameraSelected,
            event: message || "Invalid Access - No Helmet",
            timestamp: firebase.firestore.Timestamp.fromDate(new Date())
          }
        ]
      });
  };
  postEvent = message => {
    db.collection("video_events_demo")
      .doc("events")
      .set({
        list: [
          ...this.state.rawEvents,
          {
            camera: this.state.cameraSelected,
            event: message || "Valid Access",
            timestamp: firebase.firestore.Timestamp.fromDate(new Date())
          }
        ]
      });
  };

  openImage = id => {
    // console.log("clicked on open image!");
  };

  changeCamera = id => {
    this.setState({ cameraSelected: id });
  };

  render() {
    const { classes } = this.props;
    // console.log('STATUS:',this.state.loadStatus)
    let loadMessage = "";
    switch (this.state.loadStatus) {
      case 0:
        loadMessage = "Cargando video y modelo...";
        break;
      case 1:
        loadMessage = "Esperando carga del video...";
        break;
      case 2:
        loadMessage = "Esperando carga del modelo...";
        break;
      case 3:
        loadMessage = "Esperando la primera predicción";
        break;
      case 4:
        loadMessage = "Realizando la primera predicción";
        break;
      case 5:
      default:
        loadMessage = "";
    }
    return (
      <div>
        <Grid container className={classes.root} spacing={2}>
          <Grid item xs={8} style={{ display: "flex" }}>
            <Paper className={classes.paper}>
              <img
                id="imatge"
                className={classes.dropzoneImg}
                src="/images/factory.png"
                alt=""
              />
              <IconButton
                key={1}
                onClick={() => this.changeCamera(1)}
                className={classes.fab1}
                style={
                  this.state.cameraSelected === 1 ? { color: "#fbc02d" } : null
                }
              >
                <VideocamIcon
                  className={classes.cameraIcon}
                  style={
                    this.state.cameraSelected === 1
                      ? { backgroundColor: "#eeeeee" }
                      : null
                  }
                />
              </IconButton>
              <IconButton
                key={2}
                onClick={() => this.changeCamera(2)}
                className={classes.fab2}
                style={
                  this.state.cameraSelected === 2 ? { color: "#fbc02d" } : null
                }
              >
                <VideocamIcon
                  className={classes.cameraIcon}
                  style={
                    this.state.cameraSelected === 2
                      ? { backgroundColor: "#eeeeee" }
                      : null
                  }
                />
              </IconButton>
              <IconButton
                key={3}
                onClick={() => this.changeCamera(3)}
                className={classes.fab3}
                style={
                  this.state.cameraSelected === 3 ? { color: "#fbc02d" } : null
                }
              >
                <VideocamIcon
                  className={classes.cameraIcon}
                  style={
                    this.state.cameraSelected === 2
                      ? { backgroundColor: "#eeeeee" }
                      : null
                  }
                />
              </IconButton>
            </Paper>
          </Grid>
          <Grid item xs={4} style={{ display: "flex" }}>
            <Paper style={{ width: "100%" }}>
              <div className={classes.dropzonePage}>
              {
                // EZEQUIEL
                // <div
                //   className={classes.dropzonePage}
                //   style={{
                //     width:
                //       (3 *
                //         Math.min(
                //           0.35 * window.innerWidth,
                //           (4 * window.innerHeight) / 3
                //         )) /
                //       4,
                //     height:
                //       (9 *
                //         Math.min(
                //           0.35 * window.innerWidth,
                //           (4 * window.innerHeight) / 3
                //         )) /
                //       16
                //   }}
                // >
                // EZEQUIEL
                }
                <video
                  autoPlay
                  playsInline
                  muted
                  ref={this.videoRef}
                  width={
                    (3 *
                      Math.min(
                        0.35 * window.innerWidth,
                        (4 * window.innerHeight) / 3
                      )) /
                    4
                  }
                  height={
                    (9 *
                      Math.min(
                        0.35 * window.innerWidth,
                        (4 * window.innerHeight) / 3
                      )) /
                    16
                  }
                  // EZEQUIEL
                  style={{ zIndex: 1000, position: "absolute", display: this.state.loadStatus>4? "block" : "none" }}
                  // EZEQUIEL
                  // style={{ display: "none" }}
                  // EZEQUIEL
                />
                <canvas
                  style={{zIndex:1000}}
                  ref={this.canvasRef}
                  width={
                    (3 *
                      Math.min(
                        0.35 * window.innerWidth,
                        (4 * window.innerHeight) / 3
                      )) /
                    4
                  }
                  height={
                    (9 *
                      Math.min(
                        0.35 * window.innerWidth,
                        (4 * window.innerHeight) / 3
                      )) /
                    16
                  }
                />
                {
                  // EZEQUIEL
                  <canvas
                    ref={this.oldFrameRef}
                    width={
                      (3 *
                        Math.min(
                          0.35 * window.innerWidth,
                          (4 * window.innerHeight) / 3
                        )) /
                      4
                    }
                    height={
                      (9 *
                        Math.min(
                          0.35 * window.innerWidth,
                          (4 * window.innerHeight) / 3
                        )) /
                      16
                    }
                    style={{
                      display: "none"
                    }}
                  />
                  
                  // EZEQUIEL
                }
                {this.state.loadStatus < 5 ? (
                  <div style={{position:"absolute"}}>
                    <CircularProgress
                      style={{ position: "relative", top: "40%" }}
                    />
                  </div>
                ) : null
                }
              </div>
              <div style={{height:20}}>
                {this.state.loadStatus < 5 ? (
                  <LinearProgress value={(this.state.loadStatus * 100) / 5} />
                ) : null}
                {loadMessage}
              </div>
              <Typography
                gutterBottom
                variant="h5"
                component="h2"
                style={{ marginBottom: 0, marginTop: 20 }}
              >
                Camera {this.state.cameraSelected}
              </Typography>
              {
              //   <Button
              //   size="small"
              //   color="primary"
              //   style={{ marginTop: 5, marginLeft: 10, marginRight: 10 }}
              // >
              //   Disable
              // </Button>
              // <Button
              //   size="small"
              //   color="secondary"
              //   style={{ marginTop: 5, marginLeft: 10, marginRight: 10 }}
              // >
              //   Capture
              // </Button>
              }
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper>
              <Typography
                variant="h5"
                component="h4"
                style={{ marginBottom: 0, paddingTop: 8 }}
              >
                Alerts
              </Typography>
              <List className={classes.list} subheader={<li />}>
                {this.state.alertDates.map(sectionId => (
                  <li
                    key={`section-${sectionId}`}
                    className={classes.listSection}
                  >
                    <ul className={classes.ul}>
                      <ListSubheader>{sectionId}</ListSubheader>
                      {this.state.alerts
                        .filter(item => item.timestamp.includes(sectionId))
                        .map((item, idx) => (
                          <Grow
                            key={"alert-list-" + item.timestamp}
                            in={this.state.alerts.length > 0}
                            style={{
                              transformOrigin: "0 0 0"
                            }}
                            onEnter={node => {
                              if (this.startedAt < item.timestamp) {
                                node.style.backgroundColor = "#FFFFCC";
                              }
                            }}
                            onEntered={node =>
                              (node.style.backgroundColor = "white")
                            }
                            timeout={1000}
                          >
                            <ListItem button onClick={this.openImage.bind()}>
                              <ListItemIcon className={classes.iconError}>
                                <WarningRoundedIcon />
                              </ListItemIcon>
                              <ListItemText
                                primary={
                                  item.timestamp +
                                  " | CAMERA " +
                                  item.camera +
                                  " | " +
                                  item.event
                                }
                              />
                            </ListItem>
                          </Grow>
                        ))}
                    </ul>
                  </li>
                ))}
              </List>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper>
              <Typography
                variant="h5"
                component="h4"
                style={{ marginBottom: 0, paddingTop: 8 }}
              >
                Events
              </Typography>
              <List className={classes.list} subheader={<li />}>
                {this.state.eventDates.map(sectionId => (
                  <li
                    key={`section-${sectionId}`}
                    className={classes.listSection}
                  >
                    <ul className={classes.ul}>
                      <ListSubheader>{sectionId}</ListSubheader>
                      {this.state.events
                        .filter(item => item.timestamp.includes(sectionId))
                        .map((item, idx) => (
                          <Grow
                            key={"event-list-" + item.timestamp}
                            in={this.state.events.length > 0}
                            onEnter={node => {
                              if (this.startedAt < item.timestamp) {
                                node.style.backgroundColor = "#CCFFCC";
                              }
                            }}
                            onEntered={node =>
                              (node.style.backgroundColor = "white")
                            }
                            style={{ transformOrigin: "0 0 0" }}
                            timeout={1000}
                          >
                            <ListItem
                              button
                              key={idx}
                              onClick={this.openImage.bind()}
                            >
                              <ListItemIcon className={classes.iconOk}>
                                <CheckCircleOutlineRoundedIcon />
                              </ListItemIcon>
                              <ListItemText
                                primary={
                                  item.timestamp +
                                  " | CAMERA " +
                                  item.camera +
                                  " | " +
                                  item.event
                                }
                              />
                            </ListItem>
                          </Grow>
                        ))}
                    </ul>
                  </li>
                ))}
              </List>
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(Video);
