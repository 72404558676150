import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  main: {
    maxWidth: '700px',
    margin: '0 auto',
    marginTop: theme.spacing(2),
    width: '100%',
  },
  card: {
    minWidth: 275,
  },
});

function PageNotFound(props) {
  const { classes } = props;
  return (
  <div className={classes.main}>
    <Card className={classes.card}>
      <CardContent>
        <Typography variant="h5" component="h2">
          Page not found
        </Typography>
        <Typography component="p">
          The requested URL does not belong to anyone within our domain.
        </Typography>
      </CardContent>
    </Card>
  </div>
  );
}

PageNotFound.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PageNotFound);
