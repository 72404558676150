// Imports
import update from 'immutability-helper'
import { SET_MODELS_LIST, FETCH_MODELS_LIST_BEGIN } from '../actions/types';

export default function models (state = {models: [], success: true, error: '', loading: false}, action = {}) {
  switch (action.type) {

    case FETCH_MODELS_LIST_BEGIN:
      return update(state, {
        $merge: {
          models: [],
          error: '',
          loading: true,
          success: true
        }
      })

    case SET_MODELS_LIST:
      return update(state, {
        $merge: {
          models: action.models,
          error: action.error,
          success: action.success,
          loading: false
        }
      })

    default:
      return state
  }
}
