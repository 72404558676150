import React, {Component}from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
//import FormControlLabel from '@material-ui/core/FormControlLabel';
//import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Snackbar from '@material-ui/core/Snackbar';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import { Link,BrowserRouter,withRouter} from 'react-router-dom'
import { compose } from 'recompose';
import { connect } from "react-redux";
import Img from 'react-image';
import { postLogin } from '../actions/user';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import classNames from 'classnames';
import InfoIcon from '@material-ui/icons/Info';
//import CloseIcon from '@material-ui/icons/Close';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
//import IconButton from '@material-ui/core/IconButton';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const styles1 = theme => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.dark,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
});

function MySnackbarContent(props) {
  const { classes, className, message, variant } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={classNames(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
    />
  );
}

MySnackbarContent.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
};

const MySnackbarContentWrapper = withStyles(styles1)(MySnackbarContent);

const styles = theme => ({
  layout: {
    width: 'auto',
    position: "relative",
    paddingBottom: theme.spacing(2),
    display: 'flex', // Fix IE11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(6))]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(13),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
    marginBottom:theme.spacing(6),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    marginTop: theme.spacing(3),
  },
  gridPadding: {
    paddingLeft:'0px',
    paddingRight: '0px',
  },
  gridLeft: {
    paddingRight: '0px',
    paddingTop:'10px',
  },
  gridRight: {
    paddingLeft:'0px',
    paddingTop:'10px',
  },
  root: {
    maxWidth: "500px",
    width: "100%",
    position: "fixed",
    top: "55%",
    left: "50%",
    transform: "translate(-50%,-60%)",
  },
});

const IMAGE_BASE_URL = "/images/Heureka.jpg";
const IMAGE_LOGO_URL = "/images/logo_Heureka_font_neuropol_2016_V3-300x82.png";

const backgroundStyle = {
  // marginTop: '-10px',
  backgroundRepeat: 'no-repeat',
  backgroundAttachment: 'scroll',
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  width: "100%",
  height: "100vh",
  backgroundImage: `url(${IMAGE_BASE_URL})`,
  backgroundColor: 'rgba(53,56,91,0.5)'
}

const backgroundStyle4 = {
  //display: 'table-cell',
  verticalAlign: 'middle',
  textAlign: 'center',
  marginLeft: 'auto',
  marginRight: 'auto',
  display: 'block',
}

class Login extends Component {
  state = {
    irGoogle:false,
    email: '',
    password:'',
    isLoggingIn: false,
    hasError: false,
    error:{type:'info',
           message:''},
  };

  handleIrGoogle=()=>{
    const newState = {...this.state}
    newState.irGoogle = true;
    this.setState(newState);
  }

  onChange(event) {
      this.setState({
        [event.target.name]: event.target.value
      });
  }

  onSubmit = (event) => {
      event.preventDefault();

      let input = {};
      input.email = this.state.email;
      input.password = this.state.password;

      if (input.email !== "" && input.password !== "") {
        this.setState({isLoggingIn:true})

        this.props.postLogin(input).then(response => {
                // console.log(response);
                if (response.success) {
                  setTimeout(() => {this.setState({isLoggingIn: false,
                                                   hasError: false,
                                                   isIn: true})},1000);

                } else {
                  if (response.data){
                    if (response.errors.length===1){
                      this.setState({isLoggingIn:false,
                                     hasError:true,
                                     error:response.errors[0]})
                    }
                  }
                  else{
                    this.setState({isLoggingIn:false,
                                   hasError:true,
                                   error:{type:'error',
                                          message:response.error}})
                  }
                }
        });
      } else {
        this.setState({
          error: "Invalid email or password.",
          notification: false
        });
      }
  }

  handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ hasError: false,
                    isIn: false });
  };

  render(){
    const { classes } = this.props;

  return (
    <React.Fragment>
    <BrowserRouter>
      <div style={backgroundStyle}>
        <div style={{backgroundColor: "rgba(0, 0, 0, 0.6)",width:"100%",height:"100vh"}}>
          <div className={classes.root}>
          <CssBaseline />
          <main className={classes.layout} >
            <Paper className={classes.paper}>
              <Typography  variant="subtitle1" >
                <div>
                  <div style={backgroundStyle4}>
                    <Img src={IMAGE_LOGO_URL}/>
                  </div>

                  <br/>Predictive Analytics and Artificial Intelligence as a Service by <a href="http://pervasive-tech.com/"  target="_blank"  rel="noopener noreferrer">Pervasive Technologies</a>
                  <br/><br/>
                </div>
              </Typography>
              <div style={{width:'100%'}}>
                <BrowserRouter forceRefresh={true}>
                  <Button variant="contained" fullWidth color="primary" component={Link} className={classes.button} to="/auth/google">
                  Sign in with Google
                  </Button>
                </BrowserRouter  >
                <br/>
              </div>
              <div style={{width:'100%', marginTop:'20px'}}>
                <Grid container spacing={0}>
                  <Grid item xs={5} className={classes.gridLeft}>
                    <Divider/>
                  </Grid>
                  <Grid item xs={2} className={classes.gridPadding}>
                    <Typography align='center' color='textSecondary'>OR</Typography>
                  </Grid>
                  <Grid item xs={5} className={classes.gridRight}>
                    <Divider/>
                  </Grid>
                </Grid>
              </div>
              <form className={classes.form} onSubmit={this.onSubmit}>
                <FormControl style={{marginBottom:'10px'}} required fullWidth>
                  <InputLabel htmlFor="email">Email Address</InputLabel>
                  <Input
                    id="email"
                    name="email"
                    autoComplete="email"
                    value={this.state.email}
                    onChange={this.onChange.bind(this)} />
                </FormControl>
                <FormControl style={{marginBottom:'10px'}} required fullWidth>
                  <InputLabel htmlFor="password">Password</InputLabel>
                  <Input
                    name="password"
                    type="password"
                    id="password"
                    value={this.state.password}
                    autoComplete="current-password"
                    onChange={this.onChange.bind(this)}
                  />
                </FormControl>

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  Sign in
                </Button>

              </form>
            </Paper>
            <Snackbar
              open={this.state.isIn}
              variant="info"
              message="Redicrecting..."
              autoHideDuration={2000}
              onClose={this.handleSnackbarClose}
            />
            <Snackbar
              open={this.state.isLoggingIn}
              variant="info"
              message="Getting access..."
              autoHideDuration={1000}
            />
            <Snackbar
              open={this.state.hasError}
              autoHideDuration={4000}
              onClose={this.handleSnackbarClose}
            >
              <MySnackbarContentWrapper
                onClose={this.handleSnackbarClose}
                variant={this.state.error.type}
                message={this.state.error.message}
              />
            </Snackbar>
          </main>
          </div>
        </div>
      </div>
    </BrowserRouter>
    </React.Fragment>
  );
}};

Login.propTypes = {
  postLogin: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired
};

export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter,
  connect(
    null,
    { postLogin }
  )
)(Login);
