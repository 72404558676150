import React from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "recompose";
import {  withSnackbar } from "notistack";
import { withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import InboxIcon from '@material-ui/icons/Inbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Button from '@material-ui/core/Button';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FilledInput from '@material-ui/core/FilledInput';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MaskedInput from 'react-text-mask';
import Slider from '@material-ui/core/Slider';
import Grid from '@material-ui/core/Grid';
import WifiIcon from '@material-ui/icons/Wifi';
import RotateRightIcon from '@material-ui/icons/RotateRight';
import VideocamIcon from '@material-ui/icons/Videocam';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import SettingsInputAntennaIcon from '@material-ui/icons/SettingsInputAntenna';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import AccountBoxOutlinedIcon from '@material-ui/icons/AccountBoxOutlined';
import AssessmentIcon from '@material-ui/icons/Assessment';
import ExposureIcon from '@material-ui/icons/Exposure';
import CompareIcon from '@material-ui/icons/Compare';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import PersonAddDisabledIcon from '@material-ui/icons/PersonAddDisabled';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Card from './Card';


const styles = theme => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  list1: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
});

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}

      showMask
    />
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return ['Prepare yourself', 'Capture process', 'Set equivalent points'];
}

function getStepContent(step) {
  switch (step) {
    case 0:
      return 'Please place yourself in the scene. Whenever you are ready, press Next';
    case 1:
      return 'This process may take some seconds. Please do not move.';
    case 2:
      return 'As final step, place several key points (more than 10) that belong to the same position in both images';
    default:
      return 'Unknown step';
  }
}

function getStepAll(step) {
  switch (step) {
    case 0:
      return (<Card img='tenor.gif' step={step}/>)
    case 1:
      return (<Card img='r_t.png' step={step}/>)
    case 2:
      return (<Card img='final.png' step={step}/>)
    default:
      return 'Unknown step';
  }
}

function HorizontalLinearStepper() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const steps = getSteps();

  const isStepOptional = (step) => {
    return false;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepOptional(index)) {
            labelProps.optional = <Typography variant="caption">Optional</Typography>;
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <div>
        {activeStep === steps.length ? (
          <div>
            <Typography className={classes.instructions}>
              All steps completed - you&apos;re finished
            </Typography>
            <Button onClick={handleReset} className={classes.button}>
              Reset
            </Button>
          </div>
        ) : (
          <div>
            <Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography>
            <div style={{height:'500px'}}>
              {getStepAll(activeStep)}
            </div>
            <div>
              <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                Back
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleNext}
                className={classes.button}
              >
                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

class EdgeConfiguration extends React.Component {
  state = {
    flip_visual: '0º',
    flip_thermal: '0º',
    device: '/dev/video0',
    units:'ºC',
    ip: '255.255.255.255',
    temperatureTH: '36.5ºC',
    temperatureText: 'Temperature',
    flip_text: '0º',
    idThreshold: 0.5,
    maxshadow: 5,
    minage: 3,
    earlytermination: 2,
    open: false,
    openModel: false,
    minarea: '30 px',
    maxarea: '100 px',
    bitrate: '10000 bps',
    openDialog: false,
  };

  componentDidMount() {
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleTHChange = (event, newValue) => {
    this.setState({ idThreshold: newValue });
  };
  // handleChange2 = name => event => {
  //   this.setState({
  //     [name]: event.target.value,
  //   });
  // };
  handleClick = () => {
    this.setState({open: !this.state.open})
  };

  handleClickModel = () => {
    this.setState({openModel: !this.state.openModel})
  };

  handleClickOpenDialog = () =>{
    this.setState({openDialog: !this.state.openDialog})
  }

  render() {
    const { classes } = this.props;
    const { textmask } = this.state;

    return (
      <div className={classes.root}>
        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.heading}>Device Settings</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div className={classes.list1}>
              <List component="nav">
                <Divider />
                <ListItem>
                  <ListItemIcon>
                    <WifiIcon />
                  </ListItemIcon>
                  <ListItemText primary="IP" />
                    <ListItemSecondaryAction >
                      {/*<Input
                        value={textmask}
                        onChange={this.handleChange2('textmask')}
                        id="formatted-text-mask-input"
                        inputComponent={TextMaskCustom}
                      />*/}
                      <TextField
                        inputProps={{
                          name: 'ip',
                          id: 'ip-simple',
                        }}
                        value={this.state.ip}
                        margin="normal"
                        variant="outlined"
                        onChange={this.handleChange}
                        style={{backgroundColor:'#F1F3F4',width:'200px'}}
                      />
                    </ListItemSecondaryAction>
                </ListItem>
              </List>
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.heading}>Visual Camera Settings</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div className={classes.list1}>
              <List component="nav">
                <Divider />
                <ListItem>
                  <ListItemIcon>
                    <VideocamIcon />
                  </ListItemIcon>
                  <ListItemText primary="Device" />
                    <ListItemSecondaryAction>
                      <Select
                        value={this.state.device}
                        onChange={this.handleChange}
                        inputProps={{
                          name: 'device',
                          id: 'device-simple',
                        }}
                        variant="outlined"
                        style={{width:'200px',height:'32px',backgroundColor:'#F1F3F4'}}
                      >
                        <MenuItem value={'/dev/video0'}>/dev/video0</MenuItem>
                        <MenuItem value={'/dev/video1'}>/dev/video1</MenuItem>
                      </Select>
                    </ListItemSecondaryAction>
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemIcon>
                    <RotateRightIcon />
                  </ListItemIcon>
                  <ListItemText primary="Rotation" />
                    <ListItemSecondaryAction >
                      <Select
                        value={this.state.flip_visual}
                        onChange={this.handleChange}
                        inputProps={{
                          name: 'flip_visual',
                          id: 'flip_visual-simple',
                        }}
                        variant="outlined"
                        style={{width:'200px',height:'32px',backgroundColor:'#F1F3F4'}}
                      >
                        <MenuItem value={'0º'}>0º</MenuItem>
                        <MenuItem value={'90º'}>90º</MenuItem>
                        <MenuItem value={'180º'}>180º</MenuItem>
                        <MenuItem value={'270º'}>270º</MenuItem>
                      </Select>
                    </ListItemSecondaryAction>
                </ListItem>
                <Divider />
                <ListItem style={{height:'48px'}}>
                  <ListItemSecondaryAction >
                    <Button variant="contained" color="primary" style={{width:'200px'}}>
                      Test Camera
                    </Button>
                  </ListItemSecondaryAction>
                </ListItem>
              </List>
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.heading}>Thermal Camera Settings</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div className={classes.list1}>
              <List component="nav">
                <Divider />
                <ListItem>
                  <ListItemIcon>
                    <RotateRightIcon />
                  </ListItemIcon>
                  <ListItemText primary="Rotation" />
                    <ListItemSecondaryAction >
                      <Select
                        value={this.state.flip_thermal}
                        onChange={this.handleChange}
                        inputProps={{
                          name: 'flip_thermal',
                          id: 'flip_thermal-simple',
                        }}
                        variant="outlined"
                        style={{width:'200px',height:'32px',backgroundColor:'#F1F3F4'}}
                      >
                        <MenuItem value={'0º'}>0º</MenuItem>
                        <MenuItem value={'90º'}>90º</MenuItem>
                        <MenuItem value={'180º'}>180º</MenuItem>
                        <MenuItem value={'270º'}>270º</MenuItem>
                      </Select>
                    </ListItemSecondaryAction>
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemIcon>
                    <AssessmentIcon />
                  </ListItemIcon>
                  <ListItemText primary="Units" />
                    <ListItemSecondaryAction>
                      <Select
                        value={this.state.units}
                        onChange={this.handleChange}
                        inputProps={{
                          name: 'units',
                          id: 'units-simple',
                        }}
                        variant="outlined"
                        style={{width:'200px',height:'32px',backgroundColor:'#F1F3F4'}}
                      >
                        <MenuItem value={'ºC'}>ºC</MenuItem>
                        <MenuItem value={'ºF'}>ºF</MenuItem>
                      </Select>
                    </ListItemSecondaryAction>
                </ListItem>
                <Divider />
                <ListItem style={{height:'48px'}}>
                  <ListItemSecondaryAction >
                    <Button variant="contained" color="primary" style={{width:'200px'}}>
                      Test Camera
                    </Button>
                  </ListItemSecondaryAction>
                </ListItem>
              </List>
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel >
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.heading}>Calibration</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div className={classes.list1}>
              <List component="nav">
                <Divider />
                <ListItem style={{height:'48px'}}>
                  <ListItemSecondaryAction >
                    <Button variant="contained" color="primary" style={{width:'200px'}} onClick={this.handleClickOpenDialog}>
                      Calibrate
                    </Button>
                    <Dialog
                      fullWidth
                      maxWidth={'lg'}
                      open={this.state.openDialog}
                      onClose={this.handleClickOpenDialog}
                      aria-labelledby="max-width-dialog-title"
                    >
                      <DialogTitle id="max-width-dialog-title">Cameras Calibration</DialogTitle>
                      <DialogContent>
                        <HorizontalLinearStepper/>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={this.handleClickOpenDialog} color="primary">
                          Close
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </ListItemSecondaryAction>
                </ListItem>
              </List>
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.heading}>Prediction Settings</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div className={classes.list1}>
              <List component="nav">
                <Divider />
                <ListItem>
                  <ListItemIcon>
                    <ExposureIcon />
                  </ListItemIcon>
                  <ListItemText primary="Temperature threshold" />
                    <ListItemSecondaryAction >
                      <TextField
                        inputProps={{
                          name: 'temperatureTH',
                          id: 'temperatureTH-simple',
                        }}
                        value={this.state.temperatureTH}
                        margin="normal"
                        variant="outlined"
                        onChange={this.handleChange}
                        style={{backgroundColor:'#F1F3F4', width:'200px'}}
                      />
                    </ListItemSecondaryAction>
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemIcon>
                    <RotateRightIcon />
                  </ListItemIcon>
                  <ListItemText primary="Text Rotation" />
                    <ListItemSecondaryAction >
                      <Select
                        value={this.state.flip_text}
                        onChange={this.handleChange}
                        inputProps={{
                          name: 'flip_text',
                          id: 'flip_text-simple',
                        }}
                        variant="outlined"
                        style={{width:'200px',height:'32px',backgroundColor:'#F1F3F4'}}
                      >
                        <MenuItem value={'0º'}>0º</MenuItem>
                        <MenuItem value={'90º'}>90º</MenuItem>
                        <MenuItem value={'180º'}>180º</MenuItem>
                        <MenuItem value={'270º'}>270º</MenuItem>
                      </Select>
                    </ListItemSecondaryAction>
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemIcon>
                    <TextFieldsIcon />
                  </ListItemIcon>
                  <ListItemText primary="Temperature Text" />
                    <ListItemSecondaryAction >
                      <TextField
                        inputProps={{
                          name: 'temperatureText',
                          id: 'temperatureText-simple',
                        }}
                        value={this.state.temperatureText}
                        margin="normal"
                        variant="outlined"
                        onChange={this.handleChange}
                        style={{backgroundColor:'#F1F3F4', width:'200px'}}
                      />
                    </ListItemSecondaryAction>
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemIcon>
                    <SettingsInputAntennaIcon />
                  </ListItemIcon>
                  <ListItemText primary="Stream bit rate" />
                    <ListItemSecondaryAction >
                      <TextField
                        inputProps={{
                          name: 'bitrate',
                          id: 'bitrate-simple',
                        }}
                        value={this.state.bitrate}
                        margin="normal"
                        variant="outlined"
                        onChange={this.handleChange}
                        style={{backgroundColor:'#F1F3F4', width:'200px'}}
                      />
                    </ListItemSecondaryAction>
                </ListItem>
                <Divider />
                <ListItem button onClick={this.handleClickModel}>
                  <ListItemText primary="Model settings" />
                  {this.state.openModel ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={this.state.openModel} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <Divider />
                    <ListItem>
                      <ListItemIcon>
                        <CompareIcon />
                      </ListItemIcon>
                      <ListItemText primary="Identification threshold" />
                        <ListItemSecondaryAction >
                          <div style={{width:'200px'}}>
                            <Grid container spacing={2} alignItems="center" style={{height:'44px'}}>
                              <Grid item xs>
                                <Slider
                                  min={0}
                                  step={0.01}
                                  max={1}
                                  value={typeof this.state.idThreshold === 'number' ? this.state.idThreshold : 0}
                                  onChange={this.handleTHChange}
                                  aria-labelledby="input-slider"
                                  valueLabelDisplay="auto"
                                />
                              </Grid>
                            </Grid>
                          </div>
                        </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                    <ListItem>
                      <ListItemIcon>
                        <AccountBoxIcon />
                      </ListItemIcon>
                      <ListItemText primary="Maximum object area" />
                        <ListItemSecondaryAction >
                          <TextField
                            inputProps={{
                              name: 'maxarea',
                              id: 'maxarea-simple',
                            }}
                            value={this.state.maxarea}
                            margin="normal"
                            variant="outlined"
                            onChange={this.handleChange}
                            style={{backgroundColor:'#F1F3F4', width:'200px'}}
                          />
                        </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                    <ListItem>
                      <ListItemIcon>
                        <AccountBoxOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText primary="Minimum object area" />
                        <ListItemSecondaryAction >
                          <TextField
                            inputProps={{
                              name: 'minarea',
                              id: 'minarea-simple',
                            }}
                            value={this.state.minarea}
                            margin="normal"
                            variant="outlined"
                            onChange={this.handleChange}
                            style={{backgroundColor:'#F1F3F4', width:'200px'}}
                          />
                        </ListItemSecondaryAction>
                    </ListItem>
                  </List>
                </Collapse>
                <Divider />
                <ListItem button onClick={this.handleClick}>
                  <ListItemText primary="Tracking settings" />
                  {this.state.open ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={this.state.open} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <Divider />
                    <ListItem>
                      <ListItemIcon>
                        <PersonAddIcon />
                      </ListItemIcon>
                      <ListItemText primary="Minimum Age Provation" />
                        <ListItemSecondaryAction >
                          <TextField
                            inputProps={{
                              name: 'minage',
                              id: 'minage-simple',
                            }}
                            value={this.state.minage}
                            margin="normal"
                            variant="outlined"
                            onChange={this.handleChange}
                            style={{backgroundColor:'#F1F3F4', width:'200px'}}
                          />
                        </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                    <ListItem>
                      <ListItemIcon>
                        <PersonAddDisabledIcon />
                      </ListItemIcon>
                      <ListItemText primary="Early Termination Age" />
                        <ListItemSecondaryAction >
                          <TextField
                            inputProps={{
                              name: 'earlytermination',
                              id: 'earlytermination-simple',
                            }}
                            value={this.state.earlytermination}
                            margin="normal"
                            variant="outlined"
                            onChange={this.handleChange}
                            style={{backgroundColor:'#F1F3F4', width:'200px'}}
                          />
                        </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                    <ListItem>
                      <ListItemIcon>
                        <PersonOutlineIcon />
                      </ListItemIcon>
                      <ListItemText primary="Maximum Shadow Age" />
                        <ListItemSecondaryAction >
                          <TextField
                            inputProps={{
                              name: 'maxshadow',
                              id: 'maxshadow-simple',
                            }}
                            value={this.state.maxshadow}
                            margin="normal"
                            variant="outlined"
                            onChange={this.handleChange}
                            style={{backgroundColor:'#F1F3F4', width:'200px'}}
                          />
                        </ListItemSecondaryAction>
                    </ListItem>
                  </List>
                </Collapse>
              </List>
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <Grid container spacing={3} style={{paddingTop:'20px'}}>
          <Grid item xs={3}>
          </Grid>
          <Grid item xs={3}>
            <Button variant="contained" color="primary" style={{width:'200px'}}>
              Run Debug Mode
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button variant="contained" color="primary" style={{width:'200px'}}>
              Run App
            </Button>
          </Grid>
          <Grid item xs={3}>
          </Grid>
        </Grid>
      </div>
    );
  }
}

EdgeConfiguration.propTypes = {
  classes: PropTypes.object.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired
};

export default compose(
  withStyles(styles),
  withSnackbar,
)(EdgeConfiguration);
