import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import ReloadIcon from "@material-ui/icons/Loop";
import { withStyles } from "@material-ui/core/styles";

const defaultToolbarStyles = {
  iconButton: {
  },
};

class CustomToolbar extends React.Component {

  render() {
    //const { classes } = this.props;

    return (
      <React.Fragment>
        <Tooltip title={"Reload Models List"}>
          <IconButton onClick={this.props.handleReloadModels}>
            <ReloadIcon/>
          </IconButton>
        </Tooltip>
      </React.Fragment>
    );
  }

}

export default withStyles(defaultToolbarStyles, { name: "CustomToolbar" })(CustomToolbar);
