import React from "react";
import PropTypes from "prop-types";
import axios from 'axios';
// import { connect } from "react-redux";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
// import {  withSnackbar } from "notistack";
import {
  DialogTitle,
  DialogContent,
  IconButton
  // ExpansionPanel,
  // ExpansionPanelSummary,
  // ExpansionPanelDetails
} from "@material-ui/core/";
import Dialog from "@material-ui/core/Dialog";
import blue from "@material-ui/core/colors/blue";
// import moment from "moment";
import config from "../../config";
// import Imagen from "../../components/Images/Imagen";
// import ReactJson from "react-json-view";
// import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
// import Grow from "@material-ui/core/Grow";
import Results from '../../Views/NuevaPrediccion/Results'
import DeleteIcon from '@material-ui/icons/Delete';
import DeletePredictionDialog from './DeletePredictionDialog';

const styles = {
  avatar: {
    backgroundColor: blue[100],
    color: blue[600]
  },
  dialogTitle:{
    textAlign:'right',
    margin: '5px',
    padding: '0px',
  }
};

class DetailsDialog extends React.Component {
  state = {
    prediction: null,
    loaded: false,
    handleDelete: false,
  };

  handleClose = (value) => {
    this.props.onClose(value);
  };

  componentDidMount() {
    const prediction = [this.adaptPrediction(this.props.prediction, this.props.predictionDetail)];
    this.setState({ prediction: prediction, detail: this.props.predictionDetail });
  }

  adaptPrediction = (prediction, detail) => {
    // console.log('detail',detail);
    const model = this.props.models.models.find(
      model => model.id === prediction.model
    );
    let newPrediction = {
      num_detections: 0,
      timestamp: prediction.timestamp,
      id: prediction.id,
      status: prediction.status,
      username: prediction.user_displayName,
      detection_classes: [],
      detection_scores: [],
      detection_boxes: [],
      predict_classes: [],
      original_path: prediction["original_path"]
    };
    if (detail[0]){
      detail[0].detection_scores.forEach((score, idx) => {
        if (score > config.showThreshold) {
          newPrediction.num_detections = newPrediction.num_detections + 1;
          newPrediction.detection_classes.push(
            model.labelMap[String(detail[0].detection_classes[idx])]
          );
          newPrediction.detection_scores.push(detail[0].detection_scores[idx]);
          newPrediction.detection_boxes.push({xmax: detail[0].xmax[idx],
                                              ymax: detail[0].ymax[idx],
                                              xmin: detail[0].xmin[idx],
                                              ymin: detail[0].ymin[idx]});
          newPrediction.predict_classes.push(detail[0].detection_classes[idx]);
        }
      });
    }
    // console.log('newPredition',newPrediction);
    return newPrediction;
  };

  handleDelete = () => {
    this.setState({handleDelete:true})
  }

  handleCloseDialog = () => {
    this.setState({handleDelete:false})
  }

  handleDeletePrediction = () => {
    this.handleCloseDialog();
    this.handleClose({message:'Deleting prediction...',tipus:'info'});
    axios.post('/prediction/delete',this.props.prediction)
    .then((resp)=>{
      this.handleClose({message:'Prediction deleted correctly',tipus:'success'});
    })
    .catch((error)=>{
      this.handleClose({message:'Error deleting prediction',type:'error'});
    })
  }

  render() {
    const { classes, onClose, loadingDetail, predictionDetail, ...other } = this.props;
    // let imageName = this.props.prediction.original_path;
    // imageName = imageName.split('/')[imageName.split('/').length-1];

    const model = this.props.models.models.find(
      model => model.id === this.props.prediction.model
    );

    return (
      <>
      <Dialog
        onClose={this.handleClose}
        style={{width:'1200px'}}
        fullWidth={true}
        maxWidth = {'lg'}
        aria-labelledby="simple-dialog-title"
        {...other}
      >
        <DialogTitle className={classes.dialogTitle}>
            <IconButton onClick={this.handleDelete}>
                <DeleteIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent>
          {this.state.prediction ? (
            <Results prediction={this.state.prediction} model={model}/>
          ) : (
            <div />
          )}
        </DialogContent>
      </Dialog>
      <DeletePredictionDialog
        handleDeletePrediction={this.handleDeletePrediction}
        open={this.state.handleDelete}
        handleClose={this.handleCloseDialog}
      />
      </>
    );
  }
}

DetailsDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default compose(
                  withStyles(styles)
                )(DetailsDialog);
