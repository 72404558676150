import config from "../config";
import { SET_MODELS_LIST, FETCH_MODELS_LIST_BEGIN } from './types';

export function fetchModelsList(user) {
  return dispatch => {
    dispatch({
      type: FETCH_MODELS_LIST_BEGIN
    });

    console.log('start fetch');
    return fetch(`${config.url.api}get_list_models`, {
      method: 'post',

      body: JSON.stringify(user),

      headers: {
        'Content-Type': 'application/json'
      }
    }).then(
      function(response) {
        // console.log('llista models',response);
        if (response.ok) {
          response.json().then(function(response) {
            dispatch({
              type: SET_MODELS_LIST,
              models: response.models,
              error: response.error,
              success: response.success
            });
          });
        } else {
          console.log(
            "Looks like the response wasn't perfect, got status",
            response.status
          );
        }
      },
      function(e) {
        console.log("Fetch failed!", e);
      }
    );
  };
}
