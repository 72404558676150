import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "recompose";
import withStyles from "@material-ui/core/styles/withStyles";
import CssBaseline from "@material-ui/core/CssBaseline";
//import AppBar from "@material-ui/core/AppBar";
//import Toolbar from "@material-ui/core/Toolbar";
import Paper from "@material-ui/core/Paper";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
//import Typography from "@material-ui/core/Typography";
import ChooseModel from "./ChooseModel";
import Results from "./Results";
import ResultsVideos from "./ResultsVideos";
import axios from "axios";
import {  withSnackbar } from "notistack";
import UploadImages from "./UploadImages";
import LinearProgress from "@material-ui/core/LinearProgress";
import Loading from "../../components/Loading";
import { NavLink } from "react-router-dom";
import { fetchModelsList } from "../../actions/models";
import config from "../../config";
import Upload from "gcs-browser-upload";
import moment from "moment";


const styles = theme => ({
  appBar: {
    position: "relative"
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(4))]: {
      maxWidth: 1200,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(6))]: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3)
    }
  },
  stepper: {
    padding: `${theme.spacing(3)}px 0 ${theme.spacing(5)}px`
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end"
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1)
  }
});

const steps = ["Choose Model", "Upload Image", "Results"];

class NuevoRegistro extends React.Component {
  state = {
    activeStep: 0,
    loadingPostingImage: false,
    completed:0,
    //Datos establecimiento:
    model: {
      modelName: "",
      id: "",
      description: "",
      type: "",
      labelMap: {}
    },

    //Subir imagenes:
    files: [],

    // Prediction:
    prediction: [],

    // Number of images predicted
    numImagesPred: 0,

    mode:'image'
  };

  componentWillMount(nextProps, nextState) {
    if (this.props.models.models.length===0){
      this.props.fetchModelsList(this.props.user)
    }
    if(this.props.match.params.id){
      // console.log('props',this.props);
      nextState.model = this.props.models.models.filter(obj => {return obj.id===this.props.match.params.id})
      nextState.activeStep = 1;
      // this.setState({
      //   model: this.props.models.models.filter(obj => {return obj.id===this.props.match.params.id}),
      //   activeStep: 1
      // })
    }
  }

  getStepContent = step => {
    switch (step) {
      case 0:
        return (
          <ChooseModel
            models={this.props.models}
            loading={this.props.models.loading}
            model={this.state.model}
            handleSetObject={this.handleSetObject}
          />
        );
      case 1:
        return (
          <UploadImages
            model={this.state.model.modelName}
            addFile={this.addFile}
            files={this.state.files}
          />
        );
      case 2:
        // console.log("abans d'entrar", this.state.prediction);
        if (this.state.mode==='image'){
          return <Results prediction={this.state.prediction} />
        }
        else{
          return <ResultsVideos prediction={this.state.prediction} />
        }
      default:
        throw new Error("Unknown step");
    }
  };

  handleSetObject = (name, field) => event => {
    const newState = { ...this.state };
    if (name === "model") {
      // console.log('nou model');
      newState[name] = this.props.models.models.find(function(item) {
        return item.modelName === event.target.value;
      });
    } else {
      newState[name][field] = event.target.value;
    }

    this.setState(newState, () => {
      // console.log("NEW state:", this.state)
    });
  };

  handleSetValue = name => event => {
    // console.log(name,": ", event.target.value)
    this.setState(
      {
        [name]: event.target.value
      },
      () => {
        //console.log("state:", this.state )
      }
    );
  };

  allowSiguiente = () => {
    this.setState(
      prevState => ({
        activeStep: prevState.activeStep + 1,
        loadingPostingImage: false
      }),
      () => {}
    );
  };

  handleNewPrediction = () => {
    this.setState(
      prevState => ({
        activeStep: 1,
        files: [],
        prediction: [],
        numImagesPred: 0,
        loadingPostingImage: false
      }),
      () => {}
    );
  };

  handleChangeModel = () => {
    this.setState(
      prevState => ({
        activeStep: 0,
        model: {
          modelName: "",
          id: "",
          description: "",
          type: "",
          labelMap: {}
        },
        files: [],
        prediction: [],
        numImagesPred: 0,
        loadingPostingImage: false
      }),
      () => {}
    );
  };

  handleSiguiente = () => {
    switch (this.state.activeStep) {
      case 0:
        if (this.state.model.id !== "") {
          this.allowSiguiente();
          break;
        } else {
          this.props.enqueueSnackbar("Please, choose a model", {
            variant: "error"
          });
          break;
        }
      default:

    }
  };

  handleBack = () => {
    this.setState(prevState => ({
      activeStep: prevState.activeStep - 1
    }));
  };

  addFile = fileItems => {
    this.setState({
      files: fileItems
    });
  };

  doUpload(token, bucket) {
    // get an encoded file name - either from the name input or from the file itself
    // const timestamp = moment(new Date()).format("YYYY-MM-DD H:mm:ss");

    this.state.files.forEach((file,idx) => {
      const BUCKET = bucket;
      const BASE_URL = `https://www.googleapis.com/upload/storage/v1/b/${BUCKET}/o`;
      // using the simple media upload as per the spec here: https://cloud.google.com/storage/docs/json_api/v1/how-tos/simple-upload
      const queryParams = "uploadType=resumable";

      // get the file object
      // const selectedFile = file;

      const pathName = 'images-to-predict/'+file.name.split('.').splice(0,file.name.split('.').length-1).join('')+String(Math.floor(Date.now() / 1000))+'.'+file.name.split('.').pop();

      const fileName = "name=" + pathName; //encodeURIComponent

      const URL = BASE_URL + "?" + queryParams + "&" + fileName; // ;//encodeURIComponent('https://www.googleapis.com/upload/storage/v1/b/acciona-production/o?uploadType=resumable') //&upload_id=xa298sd_sdlkj2 BASE_URL + '?' + queryParams + '?' + fileName;

      // do the upload
      // headers are required, and here, we're naively getting the MIME type from the file extension
      fetch(URL, {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json",
          "Content-Length": 0,
          Authorization: `Bearer ${token.access_token}`,
          "X-Upload-Content-Type": file.type
        })
      })
        .then(r => {
          let location = r.headers.get("location");

          let upload = new Upload({
            id: moment(new Date()).format("YYYYMMDDHmmss"),
            url: location,
            contentType: file.type,
            file: file,
            onChunkUpload: info => {
              this.setState({
                completed: (100 * info.uploadedBytes) / info.totalBytes
              });
            }
          });
          upload
            .start()
            .then(() => {
              setTimeout(() => {
                this.props.enqueueSnackbar("Data uploaded to server", {
                  variant: "success"
                });
                setTimeout(() => {
                  this.props.enqueueSnackbar("Making prediction", {
                    variant: "info"
                  });
                }, 1000);
                axios
                  .post("/predict", {
                    path: 'gs://' + bucket + '/' + pathName,
                    model: this.state.model.id,
                    user: this.props.user,
                    type: this.state.model.type
                  })
                  .then(response => {
                    if (
                      response.data.success &&
                      response.data.prediction !== "OK"
                    ) {
                      let p = response.data.prediction[0]['subcollection/predictions']
                      if (response.data.prediction[0].num_frames === 1){
                        let prediction = p[0];
                        if (this.state.model.id.includes("HeurekaOD")) {
                          let newPrediction = {
                            num_detections: 0,
                            detection_classes: [],
                            detection_scores: [],
                            detection_boxes: [],
                            predict_classes: [],
                            username: response.data.prediction[0]['user_displayName'],
                            original_path: response.data.prediction[0]["original_path"]
                          };
                          prediction.detection_scores.forEach((score, idx) => {
                            if (score > config.showThreshold) {
                                  newPrediction.num_detections =
                                    newPrediction.num_detections + 1;
                                  newPrediction.detection_classes.push(
                                    this.state.model.labelMap[
                                      String(
                                        prediction
                                          .detection_classes[idx]
                                      )
                                    ]
                                  );
                                  newPrediction.detection_scores.push(
                                    prediction
                                      .detection_scores[idx]
                                  );
                                  newPrediction.detection_boxes.push(
                                    {xmax:prediction
                                      .xmax[idx],
                                     ymax:prediction
                                       .ymax[idx],
                                     xmin:prediction
                                       .xmin[idx],
                                     ymin:prediction
                                       .ymin[idx]}
                                  );
                                  newPrediction.predict_classes.push(
                                    prediction
                                      .detection_classes[idx]
                                  );
                                }
                          });

                          let newPredictionState = [...this.state.prediction];
                          newPredictionState.push(newPrediction);
                          // console.log("HOLAAAAAAAAAAAAAAAAAAAAA", newPrediction);
                          this.setState(
                            {
                              mode:'image',
                              prediction: newPredictionState,
                              numImagesPred: this.state.numImagesPred + 1
                            },
                            () => {
                              if (
                                this.state.numImagesPred === this.state.files.length
                              ) {
                                setTimeout(() => {
                                  this.allowSiguiente();
                                }, 1000);
                              }
                            }
                          );
                        }
                      }
                      else{
                        this.setState({mode:'video',
                                       prediction: response.data.prediction},()=>{
                          setTimeout(() => {
                            this.allowSiguiente();
                          }, 1000);
                        })
                        this.props.enqueueSnackbar(
                          "Video prediction on background mode",
                          { variant: "warning" }
                        );
                        this.setState({
                          loadingPostingImage: false
                        });
                      }
                    } else {
                      this.props.enqueueSnackbar(
                        "Error predicting " +
                          file.name +
                          ". " +
                          response.data.error +
                          ". Please try again later",
                        { variant: "error" }
                      );
                      this.setState({
                        loadingPostingImage: false
                      });
                    }
                  })
                  .catch(error => {
                    console.log("ERROR: ", error);
                    this.props.enqueueSnackbar(
                      error + ". Please try again later",
                      { variant: "error" }
                    );
                    this.setState({
                      loadingPostingImage: false
                    });
                  });
              }, 200);
            })
            .catch(err => {
              this.setState({
                uploading: false,
                step: 1,
                disableFilepond: false,
                blockUpload: false
              });
              console.log("er", err);
              this.props.enqueueSnackbar(
                "Error durante la carga (" +
                  err.message +
                  "). Inténtalo más tarde",
                { variant: "error" }
              );
            });
        })
        .catch(err => {
          console.log("errrrrrr", err);
          this.setState({
            uploading: false,
            step: 1,
            disableFilepond: false,
            blockUpload: false
          });
          this.props.enqueueSnackbar(
            "Error durante la carga (" + err.message + "). Inténtalo más tarde",
            { variant: "error" }
          );
        });
    });
  }

  finalizarRegistro = () => {
    //AQUÍ TAMBÉ VOLEM FER POST DE TOTA L'ALTRA INFORMACIÓ AL FIRESTORE!!!!

    if (this.state.files.length !== 0) {
      this.setState({
        loadingPostingImage: true
      });

      fetch("/get/token")
        .then(r => r.json())
        .then(data => this.doUpload(data.token, data.bucket))
        .catch(err => {
          console.log("ERROR: ", err);
          this.props.enqueueSnackbar(
            err + ". Please try again later",
            { variant: "error" }
          );
          this.setState({
            loadingPostingImage: false
          });
        });

      // this.state.files.forEach(row => {
      //   const data = new FormData();
      //
      //   data.append("file", row);
      //
      //   axios
      //     .post("/upload_image", data)
      //     .then(response => {
      //       if (response.data.success) {
      //         this.props.enqueueSnackbar(
      //           "Data uploaded to server",
      //           { variant: "success" }
      //         );
      //         axios
      //           .post("/predict", {
      //             path: response.data.path,
      //             model: this.state.model.id,
      //             user: this.props.user,
      //             type: this.state.model.type
      //           })
      //           .then(response => {
      //             if (
      //               response.data.success &&
      //               response.data.prediction !== "OK"
      //             ) {
      //
      //               console.log("RESPOSTA PREDICCIO", response.data);
      //               let p = response.data.prediction[0]['subcollection/predictions']
      //               console.log('p--->',p);
      //               if (response.data.prediction[0].num_frames === 1){
      //                 console.log('predicció imatge:',p[0]);
      //                 let prediction = p[0];
      //                 if (this.state.model.id.includes("HeurekaOD")) {
      //                   let newPrediction = {
      //                     num_detections: 0,
      //                     detection_classes: [],
      //                     detection_scores: [],
      //                     detection_boxes: [],
      //                     predict_classes: [],
      //                     username: response.data.prediction[0]['user_displayName'],
      //                     original_path: response.data.prediction[0]["original_path"]
      //                   };
      //                   prediction.detection_scores.forEach((score, idx) => {
      //                     if (score > config.showThreshold) {
      //                           newPrediction.num_detections =
      //                             newPrediction.num_detections + 1;
      //                           newPrediction.detection_classes.push(
      //                             this.state.model.labelMap[
      //                               String(
      //                                 prediction
      //                                   .detection_classes[idx]
      //                               )
      //                             ]
      //                           );
      //                           newPrediction.detection_scores.push(
      //                             prediction
      //                               .detection_scores[idx]
      //                           );
      //                           newPrediction.detection_boxes.push(
      //                             {xmax:prediction
      //                               .xmax[idx],
      //                              ymax:prediction
      //                                .ymax[idx],
      //                              xmin:prediction
      //                                .xmin[idx],
      //                              ymin:prediction
      //                                .ymin[idx]}
      //                           );
      //                           newPrediction.predict_classes.push(
      //                             prediction
      //                               .detection_classes[idx]
      //                           );
      //                         }
      //                   });
      //
      //                   let newPredictionState = [...this.state.prediction];
      //                   newPredictionState.push(newPrediction);
      //                   // console.log("HOLAAAAAAAAAAAAAAAAAAAAA", newPrediction);
      //                   this.setState(
      //                     {
      //                       mode:'image',
      //                       prediction: newPredictionState,
      //                       numImagesPred: this.state.numImagesPred + 1
      //                     },
      //                     () => {
      //                       if (
      //                         this.state.numImagesPred === this.state.files.length
      //                       ) {
      //                         setTimeout(() => {
      //                           this.allowSiguiente();
      //                         }, 1000);
      //                       }
      //                     }
      //                   );
      //                 }
      //               }
      //               else{
      //                 console.log('es vídeo!!!');
      //                 this.setState({mode:'video',
      //                                prediction: response.data.prediction},()=>{
      //                   setTimeout(() => {
      //                     this.allowSiguiente();
      //                   }, 1000);
      //                 })
      //                 this.props.enqueueSnackbar(
      //                   "Video prediction on background mode",
      //                   { variant: "warning" }
      //                 );
      //                 this.setState({
      //                   loadingPostingImage: false
      //                 });
      //               }
      //             } else {
      //               console.log("Error en prediccio. Response", response);
      //               this.props.enqueueSnackbar(
      //                 "Error predicting " +
      //                   row.name +
      //                   ". " +
      //                   response.data.error +
      //                   ". Please try again later",
      //                 { variant: "error" }
      //               );
      //               this.setState({
      //                 loadingPostingImage: false
      //               });
      //             }
      //           })
      //           .catch(error => {
      //             console.log("ERROR: ", error);
      //             this.props.enqueueSnackbar(
      //               error + ". Please try again later",
      //               { variant: "error" }
      //             );
      //             this.setState({
      //               loadingPostingImage: false
      //             });
      //           });
      //       } else {
      //         this.props.enqueueSnackbar("Could not upload image" + row.name, {
      //           variant: "error"
      //         });
      //         // this.setState({
      //         //   loadingPostingImage: false,
      //         // })
      //       }
      //     })
      //     .catch(error => {
      //       console.log("ERROR: ", error);
      //     });
      // });
    } else {
      this.props.enqueueSnackbar("Please, upload an image before continue", {
        variant: "error"
      });
    }
  };

  render() {
    const { classes } = this.props;
    const { activeStep } = this.state;

    //console.log(this.state.activeStep)
    return (
      <React.Fragment>
        <CssBaseline />
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <Stepper
              activeStep={activeStep}
              className={classes.stepper}
              alternativeLabel
            >
              {steps.map(label => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <React.Fragment>
              <React.Fragment>
                {this.getStepContent(activeStep)}
                {this.state.loadingPostingImage ? (
                  <div style={{ flexGrow: 1 }}>
                  <LinearProgress
                      color="secondary"
                      style={{ marginBottom: "12px" }}
                      variant="determinate"
                      value={this.state.completed}
                    />
                  </div>
                ) : null}
                {this.props.models.loading ? <Loading /> : null}
                <div className={classes.buttons}>
                  {activeStep !== 0 && activeStep !== 2 && (
                    <Button
                      disabled={this.state.loadingPostingImage}
                      onClick={this.handleBack}
                      className={classes.button}
                    >
                      BACK
                    </Button>
                  )}
                  {activeStep === 2 && (
                    <Button
                      onClick={this.handleChangeModel}
                      className={classes.button}
                    >
                      CHANGE MODEL
                    </Button>
                  )}
                  {activeStep === 2 && (
                    <Button
                      onClick={this.handleNewPrediction}
                      className={classes.button}
                    >
                      NEW PREDICTION
                    </Button>
                  )}
                  {activeStep !== 2 ? (
                    <Button
                      disabled={
                        activeStep < 1
                          ? this.props.models.loading
                          : activeStep < 2
                          ? this.state.loadingPostingImage
                          : true
                      }
                      variant="contained"
                      color="primary"
                      onClick={
                        activeStep < 1
                          ? this.handleSiguiente
                          : activeStep < 2
                          ? this.finalizarRegistro
                          : this.goToGallery
                      }
                      className={classes.button}
                    >
                      {activeStep < 1
                        ? "NEXT"
                        : activeStep < 2
                        ? "PREDICT"
                        : "GO TO GALLERY"}
                    </Button>
                  ) : (
                    <NavLink
                      to="/gallery"
                      style={{ textDecoration: "none", color: "unset" }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                      >
                        GO TO GALLERY
                      </Button>
                    </NavLink>
                  )}
                </div>
              </React.Fragment>
            </React.Fragment>
          </Paper>
        </main>
      </React.Fragment>
    );
  }
}

NuevoRegistro.propTypes = {
  models: PropTypes.object.isRequired,
  fetchModelsList: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired
};

function modelsState(state) {
  return {
    models: state.models,
    user: state.user
  };
}

export default compose(
  withStyles(styles),
  withSnackbar,
  connect(
    modelsState,
    { fetchModelsList }
  )
)(NuevoRegistro);
