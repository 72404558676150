import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
//import DashboardIcon from '@material-ui/icons/Dashboard';
import { NavLink } from "react-router-dom";

//import FormatListBulleted from '@material-ui/icons/FormatListBulleted';
//import ViewModule from '@material-ui/icons/ViewModule';
import HomeIcon from '@material-ui/icons/Home';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
//import StyleIcon from '@material-ui/icons/Style';
import BoardIcon from '@material-ui/icons/DeveloperBoard';
import GroupIcon from '@material-ui/icons/Group';
import GridIcon from '@material-ui/icons/GridOn';
import WidgetsItem from "@material-ui/icons/Widgets";
import CameraEnhanceIcon from '@material-ui/icons/CameraEnhance';
import RouterIcon from '@material-ui/icons/Router';



export const listItems = (props,handleCloseMenu) => (
  <div>
  <NavLink to="/inicio" activeClassName="selected" style={{ textDecoration: 'none', color: 'unset' }}>
    <ListItem button onClick={handleCloseMenu}>
      <ListItemIcon>
        <HomeIcon /*color="secondary"*//>
      </ListItemIcon>
      <ListItemText primary="Home" />
    </ListItem>
  </NavLink>
  {
    (props.user.role==='Admin')||(props.user.role==='User') ? (
      <NavLink to="/models" activeClassName="selected" style={{ textDecoration: 'none', color: 'unset' }}>
        <ListItem button onClick={handleCloseMenu}>
          <ListItemIcon>
            <BoardIcon /*color="secondary"*//>
          </ListItemIcon>
          <ListItemText primary="Models" />
        </ListItem>
      </NavLink>) :
      null
  }
  {
    (props.user.role==='Admin')||(props.user.role==='User') ? (
      <NavLink to="/gallery" activeClassName="selected" style={{ textDecoration: 'none', color: 'unset' }}>
        <ListItem button onClick={handleCloseMenu}>
          <ListItemIcon>
            <GridIcon /*color="secondary"*//>
          </ListItemIcon>
          <ListItemText primary="Gallery" />
        </ListItem>
      </NavLink>):
      null
  }
  {
    (props.user.role==='Admin')||(props.user.role==='User') ? (
      <NavLink to="/new_prediction" activeClassName="selected" style={{ textDecoration: 'none', color: 'unset' }}>
        <ListItem button onClick={handleCloseMenu}>
          <ListItemIcon>
            <CloudUploadIcon /*color="secondary"*//>
          </ListItemIcon>
          <ListItemText primary="Predict" />
        </ListItem>
      </NavLink>):
      null
  }
  {
    (props.user.company==='Pervasive Technologies' && props.user.role==='Admin') || props.user.role==='Stream' ? (
    <NavLink to="/video_stream" activeClassName="selected" style={{ textDecoration: 'none', color: 'unset' }}>
      <ListItem button onClick={handleCloseMenu}>
        <ListItemIcon>
          <CameraEnhanceIcon /*color="secondary"*//>
        </ListItemIcon>
        <ListItemText primary="VideoStream" />
      </ListItem>
    </NavLink>) :
    null
  }
  {
    props.user.company==='Pervasive Technologies' && props.user.role==='Admin' ? (
    <NavLink to="/new_train" activeClassName="selected" style={{ textDecoration: 'none', color: 'unset' }}>
      <ListItem button onClick={handleCloseMenu}>
        <ListItemIcon>
          <WidgetsItem /*color="secondary"*//>
        </ListItemIcon>
        <ListItemText primary="Train" />
      </ListItem>
    </NavLink>) :
    null
  }
  {
    props.user.company==='Pervasive Technologies' && props.user.role==='Admin' ? (
    <NavLink to="/edge_configuration" activeClassName="selected" style={{ textDecoration: 'none', color: 'unset' }}>
      <ListItem button onClick={handleCloseMenu}>
        <ListItemIcon>
          <RouterIcon /*color="secondary"*//>
        </ListItemIcon>
        <ListItemText primary="Edge Configuration" />
      </ListItem>
    </NavLink>) :
    null
  }
  {props.user.company==='Pervasive Technologies' && props.user.role==='Admin' ? (
    <NavLink to="/users" activeClassName="selected" style={{ textDecoration: 'none', color: 'unset' }}>
      <ListItem button onClick={handleCloseMenu}>
        <ListItemIcon>
          <GroupIcon /*color="secondary"*//>
        </ListItemIcon>
        <ListItemText primary="Users" />
      </ListItem>
    </NavLink>) :
    null
  }
  </div>
);
