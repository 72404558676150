import React from 'react';
// import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles(theme => ({
  main: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(2),
  },
  footer: {
    padding: theme.spacing(1),
    marginTop: 'auto',
    backgroundColor: '#9E9E9E',
    maxHeight:'100%'
  },
  typo:{
    color: "#424242",
  },
}));

export default function StickyFooter() {
  const classes = useStyles();

  return (
      <footer className={classes.footer}>
        <Container fixed>
          {
            <Typography variant="body2" className={classes.typo}>
            {'Powered by '}
            <Link color="inherit" target='_blank' href="https://www.pervasive-tech.com/">
              Pervasive Technologies S.L.
            </Link>
          </Typography>
          }
        </Container>
      </footer>
  );
}
