/**
 * @license
 * Copyright 2018 Google LLC. All Rights Reserved.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * =============================================================================
 */

import * as tf from '@tensorflow/tfjs';

// const GOOGLE_CLOUD_STORAGE_DIR = 'https://storage.googleapis.com/tfjs-models/savedmodel/';
// const MODEL_FILE_URL = 'mobilenet_v2_1.0_224/model.json';
const MODEL_FILE_PATH = '/model/web_model/model.json';
const INPUT_NODE_NAME = 'image_tensor';
const OUTPUT_NODE_NAME = 'detection_boxes';
// const PREPROCESS_DIVISOR = tf.scalar(255 / 2);

export class MobileNet {
  async load() {
    // this.model = await tf.loadGraphModel(
    //     GOOGLE_CLOUD_STORAGE_DIR + MODEL_FILE_URL);
    this.model = await tf.loadGraphModel(MODEL_FILE_PATH);
  }

  dispose() {
    if (this.model) {
      this.model.dispose();
    }
  }
  /**
   * Infer through MobileNet. This does standard ImageNet pre-processing before
   * inferring through the model. This method returns named activations as well
   * as softmax logits.
   *
   * @param input un-preprocessed input Array.
   * @return The softmax logits.
   */
  predict(input) {
    // const preprocessedInput = tf.div(
    //     tf.sub(input.asType('int8'), PREPROCESS_DIVISOR),
    //     PREPROCESS_DIVISOR);
    // const reshapedInput =
    //     preprocessedInput.reshape([1, ...preprocessedInput.shape]);
    console.log('anem a fer una inferència!!!');
    return this.model.execute(
        {[INPUT_NODE_NAME]: input}, OUTPUT_NODE_NAME);
  }

  getTopKClasses(logits, topK) {
    const predictions = tf.tidy(() => {
      return tf.softmax(logits);
    });

    const values = predictions.dataSync();
    predictions.dispose();

    let predictionList = [];
    for (let i = 0; i < values.length; i++) {
      predictionList.push({value: values[i], index: i});
    }
    predictionList = predictionList
                         .sort((a, b) => {
                           return b.value - a.value;
                         })
                         .slice(0, topK);

    return predictionList.map(x => {
      return {label: x.index, value: x.value};
    });
  }
}
