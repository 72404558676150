import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { compose } from "recompose";
import {  withSnackbar } from "notistack";
import { withStyles } from "@material-ui/core/styles";
import DetailsDialog from "../../components/Dialogs/DetailsDialog";
import {
  Table,
  TableHead,
  TablePagination,
  TableRow,
  Grid,
  Typography,
  Button,
  Tooltip,
  DialogTitle,
  DialogContent,
  Dialog
} from "@material-ui/core";
import { NavLink } from "react-router-dom";
import GalleryThumbnail from "../../components/Images/GalleryThumbnail";
import VideoThumbnail from "../../components/Images/VideoThumbnail";
import Loading from "../../components/Loading";

const styles = theme => ({});

class Galeria extends Component {
  state = {
    resultsList: null,
    rowsPerPage: 4,
    page: 0,
    pageSize: 18,
    loadedList: null,
    hasMoreItems: true,
    filteredList: null,
    displayedPrediction: null,
    open: false,
    loadingDetail: false,
    loadingVideoDetail: false,
    predictionDetail: {}
  };

  handleResultsChange = list => {
    const newState = { ...this.state };
    newState.resultsList = list.sort((a, b) => (a.timestamp < b.timestamp ? 1 : -1)).filter(function(row){
      // return row.media_type!=='video'
      return true
    })
    // console.log(newState.resultsList);
    newState.filteredList = this.applyFilters(newState.resultsList);
    newState.loadedList = newState.filteredList.filter(
      (elem, index) =>
        index >= newState.page * newState.pageSize &&
        index < (newState.page + 1) * newState.pageSize
    );
    this.setState(newState);
    //console.log(lista[0]);
  };

  applyFilters = array => {
    return this.props.models.models.length===0?[]:array.filter(prediction=>this.props.models.models.find(
      model => model.id === prediction.model
    ))
  };

  loadPage = (event, pageNumber) => {
    const newState = { ...this.state };
    newState.loadedList = [];
    newState.page = pageNumber;
    //console.log(newState.filteredList)
    newState.filteredList = this.applyFilters(newState.resultsList);
    newState.loadedList = newState.filteredList.filter(
      (elem, index) =>
        index >= newState.page * newState.pageSize &&
        index < (newState.page + 1) * newState.pageSize
    );
    //console.log(newState.loadedList)
    this.setState(newState);
  };

  handleChangeRowsPerPage = (event, page) => {
    const newState = { ...this.state };
    newState.loadedList = [];
    let startImage = newState.page * newState.pageSize;
    newState.pageSize = parseInt(event.target.value);
    newState.page = parseInt(startImage / newState.pageSize);
    newState.filteredList = this.applyFilters(newState.resultsList);
    newState.loadedList = newState.filteredList.filter(
      (elem, index) =>
        index >= newState.page * newState.pageSize &&
        index < (newState.page + 1) * newState.pageSize
    );
    this.setState(newState);
  };

  getHist = dates => {
    // console.log("about to fetch");
    axios
      .post("/past_predictions/",this.props.user)
      .then(response => {
        // console.log("received data", response.data);
        this.handleResultsChange(response.data);
      })
      .catch(error => {
        //error;
      });
  };

  handleTimeChange = event => {
    this.setState({
      dates: event.target.value,
      resultsList: [],
      loadedList: []
    });
    this.getHist(event.target.value);
  };

  handleFilterChange = (event, index) => {
    let newState = { ...this.state };
    //newState.filters[index].values = event.target.value;
    newState.loadedList = [];
    //newState.filteredList = this.applyFilters(newState.resultsList);
    newState.loadedList = newState.loadedList.filter(
      (elem, index) =>
        index >= newState.page * newState.pageSize &&
        index < (newState.page + 1) * newState.pageSize
    );
    this.setState(newState);
  };

  showDetails = prediction => {
    this.setState({
      loadingDetail: true,
    }, () => {
      axios.post('/prediction/get_detail',{id:prediction.id})
      .then(response => {
        if (response.status===200) {
          if (this.state.loadingDetail){
            this.setState({
              loadingDetail: false,
              predictionDetail: response.data,
              displayedPrediction: prediction,
              open: true
            });
          }
        }
        else{
          this.props.enqueueSnackbar(
            "Error geting prediction (response status " +
              response.status +
              ") ",
            { variant: "error" }
          );
        }
      })
      .catch(error => {
        this.props.enqueueSnackbar(
          "Error geting prediction (" +
            error +
            ") ",
          { variant: "error" }
        );
      })
    });
  };

  exportToJson = (data) => {
    let filename = "prediction.json";
    let contentType = "application/json;charset=utf-8;";
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      var blob = new Blob([decodeURIComponent(encodeURI(JSON.stringify(data)))], { type: contentType });
      navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      var a = document.createElement('a');
      a.download = filename;
      a.href = 'data:' + contentType + ',' + encodeURIComponent(JSON.stringify(data));
      a.target = '_blank';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
    setTimeout(() => {this.setState({
      loadingVideoDetail: false
    });},500);

  }

  downloadDetails = prediction => {
    this.setState({
      loadingVideoDetail: true,
    }, () => {
      axios.post('/prediction/get_detail',{id:prediction.id})
      .then(response => {
        if (response.status===200) {
          let labelMap = this.props.models.models.filter(row => row.id===prediction.model)[0].labelMap;
          response.data.forEach(row=>{
            if (row.detection_classes){
              row.detection_classes = row.detection_classes.map(box => {
                // console.log('box',box);
                return labelMap[box]
              })
            }
          });
          this.exportToJson(response.data)
        }
        else{
          this.props.enqueueSnackbar(
            "Error geting prediction (response status " +
              response.status +
              ") ",
            { variant: "error" }
          );
        }
      })
      .catch(error => {
        this.props.enqueueSnackbar(
          "Error geting prediction (" +
            error +
            ") ",
          { variant: "error" }
        );
      });
    });
  };

  handleClose = value => {
    if (value.tipus){
      this.props.enqueueSnackbar(value.message, {
        variant: value.tipus
      });
      if (value.tipus==='success'){
        this.getHist();
      }
    }
    this.setState({ displayedPrediction: null, open: false, loadingDetail:false, loadingVideoDetail:false, predictionDetail: {}});
  };

  // componentDidMount(){
  //   this.getHist();
  // }
  componentDidMount(){
    if (this.props.models.models.length>0){
      this.getHist();
    }
  }

  componentWillUpdate(nextProps, nextState){
    if(nextProps.models.loading===false&&this.props.models.loading){
      this.getHist();
    }
  }

  render() {
    const { classes } = this.props;
    var items = [];
    if (this.state.loadedList) {
      this.state.loadedList.forEach((tile, i) => {
        if (tile.media_type!=='video'){
          // console.log('filtered elements',tile);
          let imageName = tile.original_path;
          imageName = imageName.split("/")[imageName.split("/").length - 1];

          let imageThumbnailName = tile.thumbnail_path;
          imageThumbnailName = imageThumbnailName.split("/")[
            imageThumbnailName.split("/").length - 1
          ];

          // console.log('props gallery',this.props);
          items.push(
            <Grid item xs={6} key={tile.id} sm={4} md={3} lg={2}>
              <GalleryThumbnail
                file={imageName}
                thumb={imageThumbnailName}
                label={tile.timestamp}
                model={tile.model}
                image_id={tile.id}
                models={this.props.models.models}
                status={tile.status}
                onClick={() => this.showDetails(tile)
                                 // tile.detection_boxes ?
                                 //  tile.status!=="pending" ?
                                 //    this.showDetails(tile) :
                                 //    null : //console.log("this image is still pending") :
                                 //  null //console.log('image with error on prediction')
                               }
              />
            </Grid>
          );
        }
        else{
          items.push(
            <Grid item xs={6} key={tile.id} sm={4} md={3} lg={2}>
            <VideoThumbnail
              label={tile.timestamp}
              model={tile.model}
              video_id={tile.id}
              models={this.props.models.models}
              status={(tile.status)}
              onClick={() => this.downloadDetails(tile)
                               // tile.detection_boxes ?
                               //  tile.status!=="pending" ?
                               //    this.showDetails(tile) :
                               //    null : //console.log("this image is still pending") :
                               //  null //console.log('image with error on prediction')
                             }
            />
            </Grid>
          );
        }
      });
    }

    return (
      <div style={{backgroundColor:'#EEEEEE', paddingBottom:15, paddingLeft:15, paddingRight:15, borderRadius:4}}>
        <div className={classes.root}>
          {this.state.filteredList !== null ?
            this.state.filteredList.length ? (
              <>
              <Table>
                <TableHead>
                  <TableRow>
                    <TablePagination
                      count={this.state.filteredList.length}
                      rowsPerPage={this.state.pageSize}
                      page={this.state.page}
                      rowsPerPageOptions={[18, 24, 48, 96]}
                      SelectProps={{
                        native: true
                      }}
                      labelRowsPerPage="Documents per page: "
                      labelDisplayedRows={({ from, to, count }) =>
                        `${from}-${to} out of ${count}`
                      }
                      onChangePage={this.loadPage}
                      onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    />
                  </TableRow>
                </TableHead>
              </Table>
              <Grid container spacing={3} style={{ paddingTop: "20px" }}>
                {items}
              </Grid>
              </>
            ) :
            (
              <>
                <Grid>
                  <Grid item xs={12}>
                    <Typography style={{marginTop:'20px'}} variant="button" gutterBottom>
                      No predictions found
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <NavLink to={"/new_prediction/"} style={{ textDecoration: 'none', color: 'unset' }}>
                      <Tooltip title="Make a Prediction">
                        <Button variant="contained" color="primary" style={{marginTop:'10px'}}>
                          Make one
                        </Button>
                      </Tooltip>
                    </NavLink>
                    </Grid>
                </Grid>
              </>
            ) :
            (
            <div
              style={{
                width: "100%",
                height: "80vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <Loading />
            </div>)
          }
        </div>
        {this.state.loadingDetail ? (
          <Dialog
            open={this.state.loadingDetail}
            onClose={this.handleClose}
            aria-labelledby="simple-dialog-title"
            // style={{minWidth:'1200px'}}
            // style={{paperWidthSm:'lg'}}
          >
            <DialogTitle className={classes.dialogTitle}>
                Loading prediction data
            </DialogTitle>
            <DialogContent style={{width:'100%'}}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <Loading />
              </div>
            </DialogContent>
          </Dialog>
        ) : this.state.loadingVideoDetail ?
        (<Dialog
          open={this.state.loadingVideoDetail}
          onClose={this.handleClose}
          aria-labelledby="simple-dialog-title"
          // style={{minWidth:'1200px'}}
          // style={{paperWidthSm:'lg'}}
        >
          <DialogTitle className={classes.dialogTitle}>
              Loading prediction json
          </DialogTitle>
          <DialogContent style={{width:'100%'}}>
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <Loading />
            </div>
          </DialogContent>
        </Dialog>) : null}
        {this.state.open ? (
          <DetailsDialog
            style={{width:'1200px',margin:'auto'}}
            open={this.state.open}
            models={this.props.models}
            prediction={this.state.displayedPrediction}
            onClose={this.handleClose}
            loadingDetail={this.state.loadingDetail}
            predictionDetail={this.state.predictionDetail}
          />
        ) : null}
      </div>
    );
  }
}

Galeria.propTypes = {
  user: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
};

function galleryState(state) {
  return {
    models: state.models,
    user: state.user
  };
}

export default compose(
  withStyles(styles),
  withSnackbar,
  connect(
    galleryState,
    null
  )
)(Galeria);
