// Imports
import { combineReducers } from 'redux'

// App Imports
//import { user } from './user'
import authReducer from './authReducer'
import models from './models'

export default combineReducers({
    user : authReducer,
    models : models
});
