import PropTypes from "prop-types";
import React, { Component } from "react";
import axios from "axios";
import moment from 'moment';
import withStyles from '@material-ui/core/styles/withStyles';
import { connect } from "react-redux";
import { compose } from "recompose";
import {  withSnackbar } from "notistack";
import { Grid, Typography, Checkbox, FormControl } from "@material-ui/core";
import Slider from '@material-ui/lab/Slider';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

const styles = theme => ({
  dropzoneImg: {
    // maxWidth: 'calc(100vw - 60px)',
    // maxHeight: 'calc(100vw - 60px)',
    width: '100%',
    // height: '448px',
    // display:'contents',
    border: 'thin solid rgba(64, 64, 64, 0.15)',
    borderRadius: '4px',
    objectFit: 'cover',
    // maxHeight:'70vh',
  },

  dropzonePage: {
    margin: '0px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  noMargin: {
    textAlign: 'center',
  },
  canvas: {
    position: 'absolute',
    top: '10px',
    bottom: '10px',
    left: '10px',
    right: '10px',
    border: 'thin solid rgba(64, 64, 64, 0.25)',
    borderRadius: '10px',
    objectFit: 'cover',
  },
  slider: {
    marginLeft:'15px',
    marginRight:'15px',
    padding: '22px 0px',
  },
  gridTheme:{
    backgroundColor: theme.palette.background.default,
  },
  form: {
    margin: theme.spacing(3),
    marginTop: '0px',
    marginBottom: '0px',
    width: '100%',
  },
})

class Image extends Component {
  state = {
    imageURL: "",
    texto: "",
    display: 'block',
    showClassNames: true,
    showScore: true,
    score: 50,
    selectableClasses: [],
    colors:{
      0:'#d50000',
      1:'#304FFE',
      2:'#00BFA5',
      3:'#AEEA00',
      4:'#C51162',
      5:'#2962FF',
      6:'#00C853',
      7:'#FFD600',
      8:'#AA00FF',
      9:'#0091EA',
      10:'#64DD17',
      11:'#FFAB00',
      12:'#6200EA',
      13:'#00B8D4',
      14:'#FF6D00',
    }
  };

  handleImageURLChange = imageURL => {
    const newState = { ...this.state };
    newState.imageURL = imageURL;
    if (this._mounted === true) this.setState(newState);
  };

  componentDidMount() {
    this._mounted = true;
    //const filepath = this.props.file.split(bucketName)[1];
    //path: /imagenes-gpv/processed-images/filename
    // console.log('filename to get',this.props.file);
    const filepath = "images-to-predict/" + this.props.file;
    if (this.props.file) {
      axios
        .get("/imagen?filename=" + filepath)
        .then(response => {
          if (this._mounted === true) this.handleImageURLChange(response.data);
        })
        .catch(error => {
          console.log(error);
        });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    //const filepath = this.props.file.split(bucketName)[1];
    //path: /imagenes-gpv/processed-images/filename
    // console.log('other filename to get',this.props.file);
    const filepath = "images-to-predict/" + this.props.file;
    if (this.props.file !== prevProps.file) {
      axios
        .get("/imagen?filename=" + filepath)
        .then(response => {
          if (this._mounted === true) this.handleImageURLChange(response.data);
        })
        .catch(error => {
          console.log(error);
        });
    }
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  cropToCanvas = (image, canvas, ctx, load) => {
    const naturalWidth = image.naturalWidth;
    const naturalHeight = image.naturalHeight;
    // console.log('Natural vs Image width',image.width, naturalWidth,ctx.canvas.width);
    // console.log('Natural vs Image height',image.height, naturalHeight,ctx.canvas.height);

    if(load){
      canvas.width = Math.min(image.width, naturalWidth);
      canvas.height = Math.min(image.height, naturalHeight);
    }

    // console.log('image client shape:',image.clientWidth)
    // console.log('canvas shape:',canvas.width,canvas.height);

    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    ctx.drawImage(
      image,
      0,
      0,
      naturalWidth,
      naturalHeight,
      0,
      0,
      ctx.canvas.width,
      ctx.canvas.height
    );
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.checked }, () => {this.onImageChange()});
  };

  handleChangeSlider = (event, value) => {
    this.setState({ score: value }, () => {this.onImageChange()});
  };

  unique = (arr) => {
    var u = {}, a = [];
    for(var i = 0, l = arr.length; i < l; ++i){
        if(!u.hasOwnProperty(arr[i])) {
            a.push(arr[i]);
            u[arr[i]] = 1;
        }
    }
    let out = {}
    a.map(row => {return out[row] = true})
    return out;
  }

  handleChangeCheckbox = elem => event => {
    let newClass = {...this.state.selectableClasses}
    newClass[elem] = !newClass[elem]
    this.setState({selectableClasses: newClass}, () =>{this.onImageChange()})
  }

  onImageChange = () => {
    const predictions = this.props.prediction;
    const c = document.getElementById(this.props.canvasKey);
    const ctx = c.getContext("2d");
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height,false);
    this.cropToCanvas(document.getElementById('imatge'), c, ctx);

    const naturalWidth = ctx.canvas.width;
    const naturalHeight = ctx.canvas.height;
    // console.log('natural shape:',naturalWidth,naturalHeight);
    // console.log('ctx shape:', ctx.canvas.width, ctx.canvas.height);

    // this.state.model.detect(c).then(predictions => {
      // Font options.
    const font = "16px sans-serif";
    ctx.font = font;
    ctx.textBaseline = "top";

    // predictions.forEach(prediction => {
    predictions.detection_boxes.forEach((bbox,idx)=>{
      if (100*predictions.detection_scores[idx]>=this.state.score && this.state.selectableClasses[predictions.detection_classes[idx]]){
        const x = bbox.xmin*naturalWidth;
        const y = bbox.ymin*naturalHeight;
        const width = (bbox.xmax-bbox.xmin)*naturalWidth;
        const height = (bbox.ymax-bbox.ymin)*naturalHeight;
        // Draw the bounding box.
        ctx.strokeStyle = this.state.colors[predictions.predict_classes[idx]%15];
        ctx.lineWidth = 2;
        ctx.shadowColor = '#37474F';
        ctx.shadowOffsetX = 1;
        ctx.shadowOffsetY = 1;
        ctx.shadowBlur = 5;
        ctx.strokeRect(x, y, width, height);
        // Draw the label background.
        const textWidth = ctx.measureText(predictions.detection_classes[idx]).width;
        const textHeight = parseInt(font, 10); // base 10
        if (this.state.showClassNames===true){
          if (this.state.showScore===true){
            ctx.fillStyle = this.state.colors[predictions.predict_classes[idx]%15];
            ctx.fillRect(x, y, textWidth + 8 + 43, textHeight + 4);
          }
          else {
            ctx.fillStyle = this.state.colors[predictions.predict_classes[idx]%15];
            ctx.fillRect(x, y, textWidth + 8, textHeight + 4);
          }
        }
        else{
          if (this.state.showScore===true){
            ctx.fillStyle = this.state.colors[predictions.predict_classes[idx]%15];
            ctx.fillRect(x, y, 8 + 38, textHeight + 4);
          }
        }
      }
    });

    if (this.state.showClassNames===true){
      predictions.detection_boxes.forEach((bbox,idx)=>{
        if (100*predictions.detection_scores[idx]>=this.state.score && this.state.selectableClasses[predictions.detection_classes[idx]]){
          const x = bbox.xmin*naturalWidth+2;
          const y = bbox.ymin*naturalHeight+2;
          // Draw the text last to ensure it's on top.
          ctx.fillStyle = '#FAFAFA';
          ctx.fillText(predictions.detection_classes[idx], x, y);
        }
      });
    }

    if (this.state.showScore===true){
      predictions.detection_boxes.forEach((bbox,idx)=>{
        if (100*predictions.detection_scores[idx]>=this.state.score && this.state.selectableClasses[predictions.detection_classes[idx]]){
          let x = bbox.xmin*naturalWidth+2;
          let y = bbox.ymin*naturalHeight+2;
          if (this.state.showClassNames===true){
            x = bbox.xmin*naturalWidth+ctx.measureText(predictions.detection_classes[idx]).width+7;
          }
          // const x = bbox.xmin*naturalWidth+2;
          // const y = bbox.ymin*naturalHeight+2;
          // Draw the text last to ensure it's on top.
          ctx.fillStyle = '#FAFAFA';
          ctx.fillText(predictions.detection_scores[idx].toFixed(3), x, y);
        }
      });
    }
    this.setState({display:'contents'})
  }

  onImageLoad = e => {
    if (this.props.prediction.detection_boxes.length>0 && this.props.prediction.status!=='pending' ){

      this.setState({selectableClasses: this.unique(this.props.prediction.detection_classes)}, () =>{console.log(this.state.selectableClasses);})
      const predictions = this.props.prediction;
      const c = document.getElementById(this.props.canvasKey);
      const ctx = c.getContext("2d");
      this.cropToCanvas(e.target, c, ctx,true);

      const naturalWidth = ctx.canvas.width;
      const naturalHeight = ctx.canvas.height;
      // console.log('natural shape:',naturalWidth,naturalHeight);
      // console.log('ctx shape:', ctx.canvas.width, ctx.canvas.height);

      // this.state.model.detect(c).then(predictions => {
        // Font options.
      const font = "16px sans-serif";
      ctx.font = font;
      ctx.textBaseline = "top";

      // predictions.forEach(prediction => {
      predictions.detection_boxes.forEach((bbox,idx)=>{
        if (100*predictions.detection_scores[idx]>=this.state.score){
          const x = bbox.xmin*naturalWidth;
          const y = bbox.ymin*naturalHeight;
          const width = (bbox.xmax-bbox.xmin)*naturalWidth;
          const height = (bbox.ymax-bbox.ymin)*naturalHeight;
          // Draw the bounding box.
          ctx.strokeStyle = this.state.colors[predictions.predict_classes[idx]%15];
          ctx.lineWidth = 2;
          ctx.shadowColor = '#37474F';
          ctx.shadowOffsetX = 1;
          ctx.shadowOffsetY = 1;
          ctx.shadowBlur = 5;
          ctx.strokeRect(x, y, width, height);
          // Draw the label background.
          const textWidth = ctx.measureText(predictions.detection_classes[idx]).width;
          const textHeight = parseInt(font, 10); // base 10
          if (this.state.showClassNames===true){
            if (this.state.showScore===true){
              ctx.fillStyle = this.state.colors[predictions.predict_classes[idx]%15];
              ctx.fillRect(x, y, textWidth + 8 + 43, textHeight + 4);
            }
            else {
              ctx.fillStyle = this.state.colors[predictions.predict_classes[idx]%15];
              ctx.fillRect(x, y, textWidth + 8, textHeight + 4);
            }
          }
          else{
            if (this.state.showScore===true){
              ctx.fillStyle = this.state.colors[predictions.predict_classes[idx]%15];
              ctx.fillRect(x, y, 8 + 38, textHeight + 4);
            }
          }
        }
      });

      if (this.state.showClassNames===true){
        predictions.detection_boxes.forEach((bbox,idx)=>{
          if (100*predictions.detection_scores[idx]>=this.state.score){
            const x = bbox.xmin*naturalWidth+2;
            const y = bbox.ymin*naturalHeight+2;
            // Draw the text last to ensure it's on top.
            ctx.fillStyle = '#FAFAFA';
            ctx.fillText(predictions.detection_classes[idx], x, y);
          }
        });
      }

      if (this.state.showScore===true){
        predictions.detection_boxes.forEach((bbox,idx)=>{
          if (100*predictions.detection_scores[idx]>=this.state.score){
            let x = bbox.xmin*naturalWidth+2;
            let y = bbox.ymin*naturalHeight+2;
            if (this.state.showClassNames===true){
              x = bbox.xmin*naturalWidth+ctx.measureText(predictions.detection_classes[idx]).width+7;
            }
            ctx.fillStyle = '#FAFAFA';
            ctx.fillText(predictions.detection_scores[idx].toFixed(3), x, y);
          }
        });
      }
      this.setState({display:'contents'})
    }
  };

  render() {
    const { classes } = this.props;

    return (
      <Grid container className={classes.root} spacing={3}>
        <Grid item xs={12} md={3} className={classes.gridTheme}>
          <Grid container spacing={3}>
            {this.props.model? (<Grid item xs={12}>
              <Typography variant="overline" style={{marginBottom:'0'}} align='left'>
                {
                  this.props.model.modelName
                }
              </Typography>
              <Typography variant="overline" gutterBottom align='center'>
                {moment(this.props.prediction.timestamp).format("DD/MM/YYYY H:mm:ss")}
              </Typography>
            </Grid>) : null}
            {
              this.props.prediction.detection_boxes.length>0 && this.props.prediction.status!=='pending' ? (
                <>
                <Grid item xs={12}>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={this.state.showClassNames}
                          onChange={this.handleChange('showClassNames')}
                          value="checkedB"
                          color="primary"
                        />
                      }
                      labelPlacement='start'
                      label="Prediction Labels"
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12}>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={this.state.showScore}
                          onChange={this.handleChange('showScore')}
                          value="checkedB"
                          color="primary"
                        />
                      }
                      labelPlacement='start'
                      label="Prediction Scores"
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12}>
                  <div className={classes.root}>
                    <Typography align='left' style={{paddingLeft:'14px'}}>Score Threshold: {this.state.score}%</Typography>
                    <Slider
                      classes={{ container: classes.slider }}
                      value={this.state.score}
                      min={0}
                      max={100}
                      step={5}
                      onChange={this.handleChangeSlider}
                    />
                  </div>
                </Grid>
                {
                  Object.keys(this.state.selectableClasses).length>0 ? (
                    <Grid item xs={12}>
                      <div className={classes.root}>
                        <Typography align='left' style={{paddingLeft:'14px'}}>Show classes:</Typography>
                        <FormControl component="fieldset" className={classes.form}>
                          <FormGroup>
                            {Object.keys(this.state.selectableClasses).map((row,idx) =>{
                              return  <FormControlLabel key={idx}
                                        control={
                                          <Checkbox
                                            checked={this.state.selectableClasses[row]}
                                            onChange={this.handleChangeCheckbox(row)}
                                            value=""
                                            color="primary"
                                          />
                                        }
                                       label={row}
                                     />
                              // return <Typography align='left' key={idx} style={{paddingLeft:'14px'}}>{row.key}</Typography>
                            })}
                          </FormGroup>
                        </FormControl>
                      </div>
                    </Grid>
                  ) :
                  null
                }
                </>
              ) : null
            }
            <Grid item xs={12}>
              {this.props.user.role==='Admin' || this.props.user.company==='Pervasive Technologies' ?
                <Typography variant="subtitle2" gutterBottom align='left'>
                  Uploaded by {this.props.prediction.username}
                </Typography> : null
              }
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={9}>
          <div className={this.props.noMargin?classes.noMargin:classes.dropzonePage}>
            {this.state.imageURL ? (
              <img
                id='imatge'
                onLoad={this.onImageLoad}
                className={classes.dropzoneImg}
                style={{display: this.state.display}}
                src={this.state.imageURL}
                alt=""
              />
            ) : null
            }
            {
              this.props.prediction.detection_boxes.length>0 && this.props.prediction.status!=='pending' ? (
                <canvas id={this.props.canvasKey}/>)
                : null
            }
          </div>
        </Grid>
      </Grid>
    );
  }
}

Image.propTypes = {
  user: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
};


function usersListState(state) {
  return {
    // models: state.models,
    user: state.user
  };
}

export default compose(
  withStyles(styles),
  withSnackbar,
  connect(
    usersListState,
    null
  )
)(Image);

// export default withStyles(styles)(Image);
