import React from 'react';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

function ChooseModel(props) {
  const models = ['Classification','Object Detection','Segmentation']
  return (
    <React.Fragment>
      <form>
        <Typography variant="h6" gutterBottom>
          Choose a model type to use
        </Typography>
        <FormControl style={{width:'75%'}}>
          <InputLabel htmlFor="model-simple">Model Type</InputLabel>
          <Select
            value={props.modelType}
            onChange={props.handleSetObject('modelType')}
            inputProps={{
              name: 'modelType',
              id: 'model-simple',
            }}
          >
            {models.map(row=>{
              return (<MenuItem value={row} key={row}>{row}</MenuItem>)
            })}
          </Select>
        </FormControl>
      </form>
    </React.Fragment>
  );
}

export default ChooseModel;
