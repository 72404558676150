import React from 'react'
import Typography from '@material-ui/core/Typography';
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImageValidateSize from 'filepond-plugin-image-validate-size';

registerPlugin(FilePondPluginImagePreview);
registerPlugin(FilePondPluginFileValidateType);
registerPlugin(FilePondPluginImageValidateSize);
// registerPlugin(FilePondPluginFileValidateType);
// registerPlugin(FilePondPluginImageExifOrientation);
// registerPlugin(FilePondPluginImagePreview);
// registerPlugin(FilePondPluginImageCrop);
// registerPlugin(FilePondPluginImageResize);
// registerPlugin(FilePondPluginImageTransform);
// registerPlugin(FilePondPluginImageEdit);

const SubirImagenes = props => (
    <React.Fragment>
    <Typography variant="h6" gutterBottom>
      Upload an image or video to predict
    </Typography>
    <Typography variant="overline" gutterBottom>
      Model: {props.model}
    </Typography>
    {/* <DropzoneAreaComponent/> */}
    <FilePond
      labelIdle='Drag an image or video file here or <span class="filepond--label-action">browse from your computer</span>'
      files={props.files}
      allowMultiple={false}
      allowFileSizeValidation={true}
      maxFileSize={'100KB'}
      maxTotalFileSize={'100KB'}
      labelMaxFileSizeExceeded={'This file is too large. Maximum size allowed 1.5MB'}
      onupdatefiles={fileItems => {
        // Set currently active file objects to this.state
        props.addFile(fileItems.map(fileItem => fileItem.file));
      }}
      maxParallelUploads = {3}
      maxFiles={5}
      accept={"image/*;capture=camera;video/*"}
      acceptedFileTypes={['image/png', 'image/jpeg', 'video/quicktime', 'video/avi', 'video/mpeg', 'video/mp4', 'video/x-ms-wmv']}
      />
  </React.Fragment>
);

export default SubirImagenes;
