import React from "react";
//import axios from "axios";
import { connect } from 'react-redux';
import PropTypes from "prop-types";
//import { Route } from 'react-router-dom';
//import MailDialog from './MailDialog';
//import Img from 'react-image'
import Loading from "../../components/Loading";
import { withStyles } from "@material-ui/core/styles";
//import Paper from "@material-ui/core/Paper";
//import Typography from "@material-ui/core/Typography";
import CustomToolbar from './CustomToolbar';
import MUIDataTable from "mui-datatables";
import { compose } from "recompose";
import { fetchModelsList } from '../../actions/models'

const styles = theme => ({
  main:{
    marginBottom: theme.spacing(2),
  },
  root: {
    //...theme.mixins.gutters(),
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
});

class Modelos extends React.Component {
  state = {
  };

  handleReloadModels = () => {
    this.props.fetchModelsList(this.props.user)
  }

  render() {
    const { models, classes } = this.props;

    let data = []
    if(models.models){
      if(models.models.length>0){
        models.models.forEach(doc => {
          if (this.props.user.company==='Pervasive Technologies' && this.props.user.role==='Admin'){
            data.push(['',
                       doc.id,
                       doc.modelName,
                       doc.description,
                       doc.source,
                       doc.dataset,
                       doc.type])
          }
          else{
            data.push(['',
                       doc.id,
                       doc.modelName,
                       doc.description])
          }
        });
      }
    }

    let columns = [
      {
        name: "",
        options: {
          filter: false,
          sort: false
        }
      },
      {
        name: "Id",
        options: {
          filter: false,
          sort: true
        }
      },
      {
        name: "Model Name",
        options: {
          filter: false,
          sort: true
        }
      },
      {
        name: "Description",
        options: {
          filter: false,
          sort: false
        }
      }
    ];

    if (this.props.user.company==='Pervasive Technologies' && this.props.user.role==='Admin'){
      columns.push({
        name: "Base Model",
        options: {
          filter: false,
          sort: false
        }
      })
      columns.push({
        name: "Dataset",
        options: {
          filter: false,
          sort: false
        }
      })
      columns.push({
        name: "Type",
        options: {
          filter: false,
          sort: false
        }
      })
    }

    // columns.push({
    //   options: {
    //     filter: false,
    //     sort: false,
    //     customBodyRender: (value, tableMeta, updateValue) => {
    //       // console.log('table meta',tableMeta);
    //       if (tableMeta.rowData){
    //         return(
    //           <>
    //           <NavLink to={"/new_prediction/"+tableMeta.rowData[1]}>
    //             <Tooltip title="Make Prediction">
    //               <IconButton aria-label="Make Prediction">
    //                 <ChipIcon />
    //               </IconButton>
    //             </Tooltip>
    //           </NavLink>
    //           </>
    //         )
    //       }
    //     }
    //   }
    // })

    const options = {
      filterType: "checkbox",
      fixedHeader: false,
      // responsive: "scroll",
      // fixedHeader: true,
      selectableRows: false,
      elevation: 4,
      rowsPerPageOptions: [5, 10, 20],
      download: false,
      print: false,
      search: true,
      filter: false,
      viewColumns: false,
      textLabels: {
        body: {
          noMatch: "No models found",
          toolTip: "Sort"
        }
      },
      customToolbar: () => {
        return (
          <CustomToolbar handleReloadModels={this.handleReloadModels}/>
        );
      },
    };

    return (
      <div className={classes.main}>
        {this.props.models.loading ? <Loading/> : <MUIDataTable data={data} columns={columns} options={options} />}
      </div>
    );
  }
}

Modelos.propTypes = {
  models: PropTypes.object.isRequired,
  fetchModelsList: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
}

function modelsState (state) {
  return {
    models: state.models,
    user: state.user,
  }
}

export default compose(withStyles(styles),
                       connect(modelsState, {fetchModelsList}))(Modelos);
