import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
//import TextField from '@material-ui/core/TextField';
//import FormControlLabel from '@material-ui/core/FormControlLabel';
//import Checkbox from '@material-ui/core/Checkbox';
//import Img from 'react-image'
import Imagen from '../../components/Images/Imagen';
//import VisibilitySensor from 'react-visibility-sensor'
import ReactJson from 'react-json-view';
//import Slider from '@material-ui/lab/Slider';
//import Switch from '@material-ui/core/Switch';
//import FormGroup from '@material-ui/core/FormGroup';

class Results extends Component {
  state = {
    value: 50,
    checkedA: true,
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleChangeCheck = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  render(){
    const { prediction } = this.props;

    return (
      <React.Fragment>
        <Grid container spacing={4} style={{margin:'0px', width:'100%'}}>

          {prediction.map((row,idx) =>{
            // console.log('prediction row:',row);
            let imageName = row.original_path;
            imageName = imageName.split('/')[imageName.split('/').length-1];

            let out_json = {}
            out_json['num_detections'] = row.num_detections;
            out_json['detection_classes'] = row.detection_classes;
            out_json['detection_scores'] = row.detection_scores;
            out_json['detection_boxes'] = row.detection_boxes;

            return (
              <Grid item xs={12} key={idx}>
                <Imagen file={imageName} prediction={row} model={this.props.model} canvasKey={'canvas'+idx} showClassNames={this.state.checkedA}/>
                {
                  row.detection_boxes.length>0 && row.status!=='pending' ? (
                    <ExpansionPanel defaultExpanded={false} style={{marginTop:'12px'}}>
                      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="h6" gutterBottom>
                          Prediction
                        </Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <ReactJson src={out_json} />
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                    ) : null
                  }
              </Grid>)
          })}
        </Grid>
      </React.Fragment>
    );
  }
}

export default (Results);
