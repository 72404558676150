import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';

class ResultsVideos extends Component {

  render(){
    const { prediction } = this.props;

    return (
      <React.Fragment>
        <Grid container spacing={4} style={{margin:'0px', width:'100%'}}>
          {prediction.map((row,idx) =>{
            return (
            <Grid item xs={12} key={idx}>
              <Paper>
                <Typography variant="overline" style={{marginBottom:'0'}} align='center'>
                  {
                    row.dataflow_jobid
                  }
                </Typography>
                <Typography variant="overline" gutterBottom align='center'>
                  {moment(row.timestamp).format("DD/MM/YYYY H:mm:ss")}
                </Typography>
              </Paper>
            </Grid>)}
          )}
        </Grid>
      </React.Fragment>
    );
  }
}

export default (ResultsVideos);
