// Imports
import React, { Component } from 'react'
//import { connect } from 'react-redux'
import PropTypes from 'prop-types'
//import { compose } from 'recompose'
import { withStyles } from '@material-ui/core/styles';
import roles from '../../config/roles';
import { connect } from "react-redux";
import { compose } from "recompose";
import {  withSnackbar } from "notistack";
// UI Imports
import { Button, TextField, MenuItem } from '@material-ui/core'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Switch from '@material-ui/core/Switch';
import DialogTitle from '@material-ui/core/DialogTitle';

// App Imports
import { postRegister } from '../../actions/user'

const styles = theme => ({
  root: {
    maxWidth: '500px',
    margin: '0 auto',
    width: '100%',
    paddingTop: '5px',
  },
  textField: {
    marginTop: 15,
  },

  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
  },
  formControl: {
    marginTop: theme.spacing(1),
  },
  formControlLabel: {
    marginLeft: 0,
    marginRight: 0,
  },
  formSwitch: {
    marginTop: theme.spacing(1),
  },
});

class AddUserDialog extends Component {
  state = {
    displayName: '',
    email: '',
    password: '',
    company: '',
    access: true,
    role: 'User',
    password_repeated: '',
    isLoading: false,
    isLoggingIn: false,
    userEdited: false,
    backArrowClicked: false,
    display: 'content',
  }

  onSubmit (event) {
    event.preventDefault();

    let input = {};
    input.displayName = this.state.displayName;
    input.email = this.state.email;
    input.password = this.state.password;
    input.company = this.state.company;
    input.access = this.state.access;
    input.role = this.state.role;
    if (
      input.password !== "" &&
      input.email !== "" &&
      input.company !== "" &&
      input.role !== ""
    ) {
      if (this.state.password === this.state.password_repeated){

        this.setState({ isLoggingIn: true, isLoading: true });

        this.props.enqueueSnackbar("Creating user", {
          variant: "info"
        });
        this.props.postRegister(input).then(response => {
          if (response.success) {
            this.setState({
              isLoading: false,
              isLoggingIn: false,
              displayName: '',
              email: '',
              password: '',
              company: '',
              access: true,
              role: 'User',
              password_repeated: ''
            });

            this.props.enqueueSnackbar("User created correctly", {
              variant: "success"
            });
            this.props.handleClose(true)
            this.setState({ registered: true });
          } else {
            this.props.enqueueSnackbar(response.errors[0].message, {
              variant: "error"
            });
            this.setState({
              isLoading: false,
              isLoggingIn: false
            });
          }
        });

      } else {
        this.props.enqueueSnackbar("Passwords must be the same", {
          variant: "error"
        });
      }

    } else {
      this.props.enqueueSnackbar("Please fill required fields", {
        variant: "error"
      });
    }
  }

  onChange (event) {
    this.setState({
      [event.target.name]: event.target.value
    })
  }

  handleChange = name => event => {
    if (name==='access'){
      this.setState({ [name]: event.target.checked });
    }
    else {
      this.setState({
        [name]: event.target.value,
      });
    }
  };


  render () {
    const { classes } = this.props

    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.props.handleClose}
          aria-labelledby="form-dialog-title"
          //maxWidth="xl"
          fullWidth={true}
        >
          <DialogTitle style={{paddingBottom:'0px'}}>{"Add User"}</DialogTitle>
          <form id="form-tweet" onSubmit={this.onSubmit.bind(this)}>
            <DialogContent style={{paddingTop:'5px', paddingBottom: '5px'}}>
              <TextField
                className={classes.textField}
                name="displayName"
                label="Name"
                value={this.state.displayName}
                required={true}
                onChange={this.onChange.bind(this)}
                fullWidth
              />
              <TextField
                className={classes.textField}
                name="email"
                label="Email"
                value={this.state.email}
                required={true}
                onChange={this.onChange.bind(this)}
                fullWidth
              />
              <TextField
                className={classes.textField}
                name="company"
                label="Company"
                value={this.state.company}
                required={true}
                onChange={this.onChange.bind(this)}
                fullWidth
              />
              <TextField
                className={classes.textField}
                name="password"
                type="password"
                label="Pasword"
                required={true}
                value={this.state.password}
                onChange={this.onChange.bind(this)}
                fullWidth
              />
              <TextField
                className={classes.textField}
                name="password_repeated"
                type="password"
                label="Repeat Password"
                required={true}
                value={this.state.password_repeated}
                onChange={this.onChange.bind(this)}
                fullWidth
              />
              <TextField
                className={classes.textField}
                id="role"
                select
                label="Role"
                value={this.state.role}
                onChange={this.handleChange("role")}
                fullWidth
                required={true}
              >
                {roles.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <FormControl
                fullWidth={true}
                className={classes.formControl}>
                <FormControlLabel
                  className={classes.formControlLabel}
                  control={
                  <Switch
                    checked={this.state.access}
                    onChange={this.handleChange('access')}
                    value="access"
                    color="primary"
                  />
                }
                  label="Access"
                  labelPlacement='start'
                />
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.props.handleClose}>
                Cancel
              </Button>
              <Button type="submit" color="primary" autoFocus>
                Create
              </Button>
          </DialogActions>
          </form>
        </Dialog>
      </div>
    )
  }
}

AddUserDialog.propTypes = {
  postRegister: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
}

export default compose(
  withStyles(styles),
  withSnackbar,
  connect(
    null,
    {postRegister}
  )
)(AddUserDialog);
