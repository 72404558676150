// Imports
import jwtDecode from 'jwt-decode'

export const USER_CURRENT_SET = 'USER_CURRENT_SET'
export const FETCH_USERS_BEGIN = 'FETCH_USERS_BEGIN'
export const SET_USERS = 'SET_USERS'
export const FETCH_ADD_USERS_BEGIN = 'FETCH_ADD_USERS_BEGIN'
export const SET_ADD_USERS = 'SET_ADD_USERS'
export const FETCH_EDIT_USERS_BEGIN = 'FETCH_EDIT_USERS_BEGIN'
export const SET_EDIT_USERS = 'SET_EDIT_USERS'
export const FETCH_DELETE_USERS_BEGIN = 'FETCH_DELETE_USERS_BEGIN'
export const SET_DELETE_USERS = 'SET_DELETE_USERS'

export function postLogin (credentials) {
  return dispatch => {
    return fetch(`/firebase_login`, {
      method: 'post',

      body: JSON.stringify(credentials),

      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        if (response.ok) {
          return response.json()
        }
      })
      .then(response => {
        if (response.success) {
          const token = response.data.token

          localStorage.setItem('token', token)
          dispatch(setCurrentUser(jwtDecode(token)))
        }

        return response
      })
      .catch(response => {
        return {error:'Could not conect to server'}
      })
  }
}

export function setCurrentUser (user) {
  return {
    type: USER_CURRENT_SET,
    user
  }
}

export function userLogout () {
  return dispatch => {
    localStorage.removeItem('token')

    dispatch(setCurrentUser({}))

    return {success: true}
  }
}


export function deleteUser (user) {
  const token = localStorage.getItem('token')

  return dispatch => {
    return fetch(`/user/delete`, {
      method: 'post',

      body: JSON.stringify(user),

      headers: {
        'Content-Type': 'application/json',
        'x-access-token': token
      }
    })
      .then(response => response.json())
  }
}

export function postEdited (user) {
  const token = localStorage.getItem('token');

  return dispatch => {
    return fetch(`/user/edit`, {
      method: 'post',

      body: JSON.stringify(user),

      headers: {
        'Content-Type': 'application/json',
        'x-access-token': token
      }
    })
      .then(response => response.json())
  }
}

export function postChange(user) {
  const token = localStorage.getItem("token");

  return dispatch => {
    return fetch(`/user/change_password`, {
      method: "post",

      body: JSON.stringify(user),

      headers: {
        'Content-Type': 'application/json',
        'x-access-token': token
      }
    })
      .then(response => response.json())
  }
}

export function postRegister (user) {
  return dispatch => {
    // console.log('enviant usuari per registrar',user);
    return fetch(`/user/register`, {
      method: 'post',

      body: JSON.stringify(user),

      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(response =>{
      if (response.ok) {
        return response.json()
      }
    })
    .then(response => {
      if (response.success) {
        // console.log('usuari creat',response);
      }

      return response
      })
    .catch(response => {
      return {error:'Could not conect to server'}
    })
  }
}

export function fetchUsers () {
  return dispatch => {
    dispatch({
      type: FETCH_USERS_BEGIN
    })

    return fetch(`/users`).then(function (response) {
      if (response.ok) {
        response.json().then(function (response) {
          if (response.data.length > 0) {
            dispatch({
              type: SET_USERS,
              users: response.data
            })
          }
        })
      } else {
        console.log('Looks like the response wasn\'t perfect, got status', response.status)
      }
    }, function (e) {
      console.log('Fetch failed!', e)
    })
  }
}
